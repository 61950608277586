import store from "@/store";
import { PartnerSettingsState } from "@/store/modules/partnerModule";
import { StoreState } from "@/store/modules/storeModule";
import Currency  from "./Currency";
import OrderType from "./OrderType";

export default class PaymentMethod {

    pMethodInt: number = 0;                 // int that identifies the method of payment
    paymentType: string = "";             // brief of the monatery 
    paymentMethodString: string = "";     // string to display the methode type
    paymentTerms: string = "";            // the payment terms field
    availableCurrencies: Array<Currency> = []; // available currencies for the payment method    
    icon: string = "";

  

    static methodUnknownInt: number = 0;
    static cashOnDeliveryInt: number = 1;
    static ccOnDeliveryInt: number = 2;
    static cashOnPickupInt: number = 3;
    static ccOnPickupInt: number = 4;
    static OnlieCardPayment: number = 11;
    static millionBridgesOnDeliveryInt: number = 101;
    static millionBridgesOnPickupInt: number = 102;

    static paymentMethodsIntForDelivery = [PaymentMethod.cashOnDeliveryInt, PaymentMethod.ccOnDeliveryInt, PaymentMethod.OnlieCardPayment /*PaymentMethod.millionBridgesOnDeliveryInt*/] as number[];
    static paymentMethodsIntForPickup = [PaymentMethod.cashOnPickupInt, PaymentMethod.ccOnPickupInt,  PaymentMethod.OnlieCardPayment  /*PaymentMethod.millionBridgesOnPickupInt*/] as number[];

    static iconsPath:string = "../assets/icons/paymentMethods/"

    static getListOfAvailablePaymentMethods(availablePaymentMethodsFromServer: any, defaultStoreCurrency: Currency): PaymentMethod[]{
        var list = [] as PaymentMethod[];

        availablePaymentMethodsFromServer.forEach((pm: any) => {
            if(this.paymentMethodsIntForDelivery.concat(this.paymentMethodsIntForPickup).includes(pm.formOfPayment.code)) {
                // get the payment method
                var paymentMethodToAdd = this.getPaymentMethodsFromInt(pm.formOfPayment.code)
                
                // add available currencies of the payment method
                paymentMethodToAdd.availableCurrencies = [];

                pm.availableCurrencies.forEach((currency: any) => {

                    if(paymentMethodToAdd.pMethodInt != this.OnlieCardPayment) {
                        paymentMethodToAdd.availableCurrencies.push({
                            id: currency.id,
                            currencyCode: currency.currencyCode,
                            isDefault: false,
                            currencyDescription: currency.currencyDescription,
                            roundingType: currency.roundingType
                        })
                    }

                    else {
                        if(typeof defaultStoreCurrency != "undefined" && currency.id == defaultStoreCurrency.id) {
                            paymentMethodToAdd.availableCurrencies.push({
                                id: currency.id,
                                currencyCode: currency.currencyCode,
                                isDefault: false,
                                currencyDescription: currency.currencyDescription,
                                roundingType: currency.roundingType
                            })
                        }
                    }                    


                });

                list.push(paymentMethodToAdd);

            }
        });

        return list;
    }

    static getListOfAvailablePaymentMethodsByOrderType(orderType: number): PaymentMethod[]{
        let availablePaymentMethodsFromServer = ((store.state as any)["store"] as StoreState).selectedStoreDetails.availableListOfPaymentMethods;    

        var list = [] as PaymentMethod[];

        if(orderType == OrderType.deliveryOrderTypeInt) {
            for(let i = 0; i < availablePaymentMethodsFromServer.length; i++) {
                if(PaymentMethod.paymentMethodsIntForDelivery.includes(availablePaymentMethodsFromServer[i].pMethodInt)) {
                    list.push(availablePaymentMethodsFromServer[i]);
                }
            }
        }

        else if(orderType == OrderType.pickupOrderTypeInt) {
            for(let i = 0; i < availablePaymentMethodsFromServer.length; i++) {
                if(PaymentMethod.paymentMethodsIntForPickup.includes(availablePaymentMethodsFromServer[i].pMethodInt)) {
                    list.push(availablePaymentMethodsFromServer[i]);
                }
            }
        }

        return list;        
    }

    static getPaymentMethodsFromInt(int: number): PaymentMethod {
        let appDataContentV2 = ((store.state as any)["partner"] as PartnerSettingsState).appDataContentV2;

        switch (int) {
            case 0:
                return {pMethodInt: 0, paymentType: "", paymentMethodString: appDataContentV2.PaymentMethodUnknownText, paymentTerms: "", icon: "" } as PaymentMethod;
            
            case 1:
                return {pMethodInt: 1, paymentType: appDataContentV2.PaymentMethodCashText, paymentMethodString: appDataContentV2.PaymentMethodCashText, paymentTerms: appDataContentV2.PaymentMethodCashOnDeliveryDescriptionText, icon: PaymentMethod.iconsPath + 'visa.png'} as PaymentMethod;
            
            case 2:
                return {pMethodInt: 2, paymentType: appDataContentV2.PaymentMethodCardText, paymentMethodString: appDataContentV2.PaymentMethodCardText, paymentTerms: appDataContentV2.PaymentMethodCardDescriptionOnDeliveryText, icon: ""} as PaymentMethod;
            
            case 3:
                return {pMethodInt: 3, paymentType: appDataContentV2.PaymentMethodCashText, paymentMethodString: appDataContentV2.PaymentMethodCashText, paymentTerms: appDataContentV2.PaymentMethodCashOnPickupDescriptionText, icon: ""} as PaymentMethod;
            
            case 4:
                return {pMethodInt: 4, paymentType: appDataContentV2.PaymentMethodCardText, paymentMethodString: appDataContentV2.PaymentMethodCardText, paymentTerms: appDataContentV2.PaymentMethodCardDescriptionOnPickupText, icon: ""} as PaymentMethod;
            
            case 11:
                return {pMethodInt: 11, paymentType: appDataContentV2.PaymentMethodOnlinePaymentText, paymentMethodString: appDataContentV2.PaymentMethodOnlinePaymentText, paymentTerms: appDataContentV2.PaymentMethodOnlinePaymentDescriptionText, icon: ""} as PaymentMethod;                 
            
            // case 101:
            //     return {pMethodInt: 101, paymentType: appDataContentV2.mbOnDeliveryPayment, paymentMethodString: appDataContentV2.mbOnDeliveryPaymentOption, paymentTerms: appDataContentV2.mbOnDeliveryTerms, icon: ""} as PaymentMethod;
            
            // case 102:
            //     return {pMethodInt: 102, paymentType: appDataContentV2.mbOnPickupPayment, paymentMethodString: appDataContentV2.mbOnPickupPaymentOption, paymentTerms: appDataContentV2.mbOnPickupTerms, icon: ""} as PaymentMethod;

            default:
                return {pMethodInt: 0, paymentType: "", paymentMethodString: appDataContentV2.PaymentMethodUnknownText, paymentTerms: "", icon: ""} as PaymentMethod;            
        }
    }

}  