export class Settings {
  public static partner_id = 1;
  public static partner_name = "La Valeur";
  public static partner_name_normalized = "valeur";
  public static client_id = "ospclient";
  public static client_secret = "secret";
  public static scope = "ospapi";
  public static grant_type = "phonenumbergrant";
  public static is_new_user = false;
  public static device_type = "web";
  public static old_fcm_token = "";
  public static new_fcm_token =
    "fIILKkAuzOg:APA91bGJxn9R18NPmcl5XkC8WEDWYq2eYhQdgode89AekqQhG32SVaDjvrsUcGS6Y1Y2Ln6TxtELuOhcbKlmaI5rEU3P7AHg9AoGYOEWnYkp1vKQxwMc4nGkeSV3ClwBJvaujPfgUqI2";
}

export class ImageSettings {
  public static baseItemsUrl: string = `https://suppystorage.blob.core.windows.net/${Settings.partner_name_normalized}/items`;
  public static baseSubcatUrl: string = `https://suppystorage.blob.core.windows.net/${Settings.partner_name_normalized}/subcategories/${Settings.partner_id}`;
}
