
import Vue from 'vue'
import { mapState } from 'vuex';
export default Vue.extend({
    props: {
        loading: {
            required: false,
            default: false,
            type: Boolean
        },

        readonly: {
            required: false,
            default: false,
            type: Boolean
        },

        searchPattern: {
            required: false,
            default: "",
            type: String
        }
    },

    computed: {

        ...mapState("partner", ["appDataContentV2"]),
        
        searchModel: {
            get() : string {
                if(this.readonly == true)
                    return "";
                
                return this.searchPattern
            },

            set(value: string) {
                
                this.$emit("changed", this.readonly == true ? "" : value);
            }
        }
    },

    model: {
        event: "changed",
        prop: "searchPattern"
    },

    methods: {
        onClick() {
            this.$emit("onClick");
        },

        onFocus() {
            this.$emit("onFocus");
        },

        onBlur() {
            this.$emit("onBlur");
        },

        onEnterKeyPress() {
            this.$emit("onEnterKeyPress");
        },
    }
})
