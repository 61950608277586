
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import OrderType from './models/OrderType';
import PartnerSettings from './models/PartnerSettings';
import User from './models/User';
import { RouteMetaInterface } from './routerManagerNew/routes';
import StoreService from './services/storeService';

export default Vue.extend({
  name: "App",


  data:() => ({
    screenSize: 0 as number,
    isLandingPageDisplayed: false as boolean,
  }),

  computed: {
    ...mapGetters("auth", ["currentSelectedUser"]),
    ...mapState("auth", ["listOfUsers", "isWebInitializing"]),
    ...mapState("partner", ["partnerSettings"]),
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("maps", ["address"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),
    ...mapState("loading", ["partnerSettingsLoading"]),

    user(): User {
      return (this as any).currentSelectedUser as User;
    },

    snackbar: {
      get(): any {
        return this.$store.state["ui"].snackbar;
      },
      set(value: any) {
        this.$store.commit("ui/snackbar", value);
      },
    }
  },

  methods: {
    ...mapActions("partner", ["getPartnerSettings", "getPartnerDataContent"]),
    ...mapActions("schedule", ["getStoreHomePageFirstAvailableSlots"]),
    ...mapActions("maps", ["getAddress"]),

    ...mapMutations("auth",["ADD_LOCAL_ADDRESS"]),
    ...mapMutations("auth", ["SET_GUEST_USER", "INITIALIZE_TOKEN", "UPDATE_WEB_INIT_STATE"]),
    
    async initApp() {
      this.$store.commit("auth/SET_ORDER_TYPE", OrderType.unknowOrderTypeInt);

      if(Object.keys((this as any).listOfUsers).length == 0) {          
        this.SET_GUEST_USER();
        this.$store.commit("auth/SET_ORDER_TYPE", OrderType.unknowOrderTypeInt);
      }

      this.INITIALIZE_TOKEN();

      ////----------------------------------////
      //// 1- Get App data content
      //// 2- Get partner settings
      //// 3- Get user
      //// 3- Check if user have address
      ////  3.1- if user has address, go to stores page
      ////  3.2- if not go to landing page
      ////----------------------------------////

      // -------------------------------------------------------------
      // get data content + partner settings + set partner main color
      // -------------------------------------------------------------  
      await Promise.all([
        this.getPartnerDataContent(),
        this.getPartnerSettings()
      ]).then(async () => {
        // if user don't have any address => set the default address from partner settings
        if(typeof this.user.usedAddress.position.latitude == "undefined" && typeof this.user.usedAddress.position.longitude == "undefined") {
          await this.getAddress({
            latitude: ((this as any).partnerSettings as PartnerSettings).defaultUserLocationCoordinates.latitude,
            longitude: ((this as any).partnerSettings as PartnerSettings).defaultUserLocationCoordinates.longitude,
          });

          this.ADD_LOCAL_ADDRESS({
            isDefault: true,
            addressInfo: (this as any).address,
            addressPosition: {
              lat: ((this as any).partnerSettings as PartnerSettings).defaultUserLocationCoordinates.latitude, 
              lng: ((this as any).partnerSettings as PartnerSettings).defaultUserLocationCoordinates.longitude
            },
            additionalAddressInformation: "",
            addressId: 0
          }); 
        }

        if(typeof (this.$route.meta as RouteMetaInterface).deepLink == "undefined" || (this.$route.meta as RouteMetaInterface).deepLink == false) {
          // get branch details if needed
          // it is used when the user was in store pages and reloaded the web
          // or if he enter the web from a deep link
          var storeId = parseInt(this.$route.params["storeId"]);
          if(typeof storeId != "undefined" && storeId != null && storeId > 0) {
            
            // get list of stores
            await StoreService.getListOfEligibleStoresFromServer();
            
            // get store details
            await StoreService.getStoreDetails(storeId);

            // get store first available slots
            await this.getStoreHomePageFirstAvailableSlots();
          }
        }
        
        this.UPDATE_WEB_INIT_STATE(false);
       })        
    },


    setScreenSize() {        
      this.screenSize = window.innerWidth;

      
      if(this.screenSize < 1050) {
        if(this.isMobileViewDisplayed == false) {
          this.$store.commit("ui/SET_MOBILE_VIEW", true)
        }
      }
      else {
        if(this.isMobileViewDisplayed == true) {
          this.$store.commit("ui/SET_MOBILE_VIEW", false);
        }
      }
      


      if(this.screenSize < 1264)
        this.isLandingPageDisplayed = true;
      else 
        this.isLandingPageDisplayed = false;
    }
  },

  watch: {
    partnerSettingsLoading(val: boolean) {
      if(val == false && ((this as any).partnerSettings as PartnerSettings).isWsaEnabled == false) {
        this.$router.push({name: "landingPage"});
      }
    }
  },
  
  created() {          
    this.UPDATE_WEB_INIT_STATE(true);
  },


  async mounted() {
    document.addEventListener('contextmenu', event => event.preventDefault());

    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize, { passive: true });
    await this.initApp();
  }
})
