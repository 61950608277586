
import Vue from "vue";
import UIButton from "@/components/UI/Button/UIButton.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment-timezone";
import UIDatePicker from "@/components/UI/TextFields/UIDatePicker.vue";
import UIGenderPicker from "@/components/UI/Pickers/UIGenderPicker.vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import Card from "@/components/UI/Card/CardRounded.vue";
import WsaTextField from "../UI/TextFields/WsaTextField.vue";
import UserGender from "@/models/UserGender";
import GenderComponent from '@/components/Authentication/GenderComponent.vue'
import WsaButton from "../UI/Button/wsa-button.vue";
import User from "@/models/User";

export default Vue.extend({
  components: {
    "ui-button": UIButton,
    "ui-date-picker": UIDatePicker,
    "ui-gender-picker": UIGenderPicker,
    "ui-title": UITitle,
    card: Card,
    "wsa-text-field": WsaTextField,
    "gender-component": GenderComponent,
    "wsa-button": WsaButton
  },
  data: () => ({
    dialog: false,
    a: "",
    params: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      phoneNumber: "",
      access_token: "",
      email: "",
      gender: UserGender.noneGender as UserGender,
    } as any,
    
    menu: false as boolean,
    loading: false as boolean
  }),

  computed: {
    ...mapState("auth", ["listOfUsers", "currentUser", "editLoading"]),
    ...mapState("partner", ["appDataContentV2"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),
    ...mapGetters("auth", ["currentSelectedUser"]),

    user(): User {
      return this.currentSelectedUser as User;
    }
  },

  methods: {
    ...mapActions("auth", ["editAccount"]),
    ...mapMutations("auth", ["UPDATE_USER"]),
    
    // prevent numbers for first name and last name
    checkNumberDigit(e: any) {
        if (Number.isInteger(parseInt(e.key))) e.preventDefault();
        else return true;
    },
    
    edit() {

      if(this.params.firstName.trim().length < 2 || this.params.lastName.trim().length < 2) {                              
        this.$store.commit("ui/snackbar", {
            error: true,
            show: true,
            message: this.appDataContentV2.AccountDetailsEmptyFristOrLastNameErrorText
        });
      }
      
      else{      
        this.loading = true;

        let user = {
          firstName: this.params.firstName,
          lastName: this.params.lastName,
          dateOfBirth: moment(this.params.dateOfBirth).format("YYYY-MM-DD"),
          phoneNumber: this.params.phoneNumber,
          email: this.params.email,
          gender: this.params.gender == null || typeof this.params.gender == "undefined" ? UserGender.noneGender : this.params.gender.genderInt,
        };
        this.editAccount(user).then(() => {
          this.UPDATE_USER(this.params);
        }).finally(() => {
          this.loading = false;
        });
      }
      
    },
  },
  mounted() {
    this.params = Object.assign({}, this.user.personalInfo);
    this.params.dateOfBirth = moment(this.user.personalInfo.dateOfBirth).format(
      "YYYY-MM-DD"
    );
  },
});
