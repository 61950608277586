
import Vue from "vue";
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import { ImageSettings } from "@/Settings/Settings";
import UIPopup from "@/components/UI/Popup/UIPopup.vue";
import { mapState } from "vuex";
import OrderType from "@/models/OrderType";
import CloseIcon from "../UI/Icons/CloseIcon.vue";

export default Vue.extend({
  components: {
    "ui-money-format": UIMoneyFormat,
    "ui-popup": UIPopup,
    "close-icon": CloseIcon,
  },
  props: ["order"],

  data: () => ({
    itemsImageUrl: ImageSettings.baseItemsUrl,
    deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt
  }),

  computed: {
    ...mapState("partner", ["appDataContentV2"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),
  }


});
