
import Vue from 'vue';
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue";
import _requestHelper from '@/apiManager/_requestHelper';
import { Apis } from '@/apiManager/Apis';
import {OrderStatus, OrderParentStatus } from '@/models/OrderStatus';
import { mapState } from 'vuex';
import OrderType from '@/models/OrderType';
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import PartnerSettings from '@/models/PartnerSettings';
import { ImageSettings } from '@/Settings/Settings';
import EmptyCart from "@/components/Cart/EmptyCart.vue";
import PulseComponent from "@/components/UI/SpinKit/UIPulseComponent.vue";
import AppDataContentV2 from '@/models/AppDataContentV2';

export default Vue.extend({
    components: {
        "header-bar": HeaderBar,
        "empty-cart": EmptyCart,
        "pulse-component": PulseComponent
    },

    data: () => ({
        loading: false as boolean,
        ordersList: [] as any[], // orders list container
        tab: 0 as number,
        itemsImageUrl: ImageSettings.baseItemsUrl,
        AppDataContentV2: AppDataContentV2
    }),

    computed: {
        ...mapState("order", ["ordersListStatuses"]),
        ...mapState("partner", ["appDataContentV2", "partnerSettings"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        listOfOrdersStatuses (): any[] {
            return [
                {text: OrderParentStatus.getParenOrderStatusByStatusInt(OrderParentStatus.ongoingOrdersParentStatus).displayStr , value: OrderParentStatus.listOfOngoingOrdersStatusesInt},
                {text: OrderParentStatus.getParenOrderStatusByStatusInt(OrderParentStatus.completedOrdersParentStatus).displayStr , value: OrderParentStatus.listOfCompletedOrdersStatusesInt},
                {text: OrderParentStatus.getParenOrderStatusByStatusInt(OrderParentStatus.cancelledOrdersParentStatus).displayStr , value: OrderParentStatus.listOfCancelledOrdersStatusesInt},
            ]
        },

        numberOfItemsPreviewToBeDisplayed (): number {
            return (this as any).isMobileViewDisplayed ? 4 : 5
        }
    },

    methods: {
        
        // get order status text
        getOrderStatusDisplayText(orderStatus: number): string {
            return OrderStatus.getStatusFromStatusInt(orderStatus).displayStatus;
        },

        // check if the order status is awaiting alternatives
        isOrderInAwaitingAlternativesStatus(status: number): boolean { 
            return status == OrderStatus.awaitingForAlternativeStatus
        },

        // get order type text
        getOrderTypeDisplayText(orderTypeInt: number): string {
        return OrderType.getOrderTypeFromInt(orderTypeInt).displayStr;
        },


        // check if store type is agnostic
        getIsAgnosticStoreType(): boolean {
        return ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel
        },


        // check if the order is a delivery order
        getIsDeliveryOrder(orderTypeInt: number): boolean {
        return orderTypeInt == OrderType.deliveryOrderTypeInt
        },

        // get pulse component speed and color
        getPulseComponentSettings(status: number) {
            if(this.tab == 0 && status !== OrderStatus.awaitingForAlternativeStatus && status !== OrderStatus.scheduledOrderStatus) {
                return {
                    speed: "normal",
                    color: "orange",
                }
            }
            else if(this.tab == 0 && status === OrderStatus.awaitingForAlternativeStatus){
                return {
                    speed: "high",
                    color: "red",
                } 
            }
            else if(this.tab == 0 && status === OrderStatus.scheduledOrderStatus) {
                return {
                    speed: "normal",
                    color: "grey",
                } 
            }
        },
        
        // format date
        getDayMonthYearFromDate(date: string) {
            const inputDate = new Date(date);

            const day = inputDate.getDate();
            const month = inputDate.toLocaleString('default', { month: 'short' });
            const year = inputDate.getFullYear().toString().slice(2);

            const currentYear = new Date().getFullYear();

            if(currentYear == inputDate.getFullYear()) {
                return `${month} ${day}`
            }
            return `${month} ${day}, ${year}`;
        },
        
        // format time
        getTimeFromDate(date: string, fullTime: boolean = false) {
            const inputDate = new Date(date);

            let hour = inputDate.getHours();
            let mins = inputDate.getMinutes();
            const amPM = hour >= 12 ? 'PM' : 'AM';

            // Convert to 12-hour format
            hour = hour % 12 || 12;
            var minsDigit = Math.floor(Math.log10(Math.abs(mins))) + 1; 
   

            if(fullTime == true) {
                if((minsDigit == 1 || -Infinity) && mins == 0) {
                    return `${hour}:00 ${amPM}`;
                }
                else if(minsDigit == 1 && mins > 0){
                    return `${hour}:0${mins} ${amPM}`;
                }
                else {
                    return `${hour}:${mins} ${amPM}`;
                }
            }
            else {
                return `${hour} ${amPM}`;
            }

        },

        // get list of orders
        getOrdersList(statuses: any, index: number = 0) {
            this.tab = index;

            if(typeof (this as any).ordersListStatuses[this.tab] != "undefined") {
                this.loading = true;
                return _requestHelper({
                    loaderKey: "",
                    method: "POST",
                    url: Apis.getUserOrderSubURL,
                    data: { 
                        statuses: statuses, 
                        appBuild: "web" 
                    },
                    successCallback: (ordersList: any) => {
                        this.ordersList = ordersList;
                    },
                })
                .catch(() => this.loading = false)
                .finally(() => this.loading = false);
            }
        }
    },

    mounted() { 
        this.getOrdersList(this.listOfOrdersStatuses[0].value)
    }
})
