
import Vue from 'vue'
import { mapState } from 'vuex'
import WsaTextField from "@/components/UI/TextFields/WsaTextField.vue";
import UITitle from '../UI/Text/UITitle.vue';
import CloseIcon from '../UI/Icons/CloseIcon.vue';
import WsaButton from '../UI/Button/wsa-button.vue';


export default Vue.extend({
    props: ["specialRequestValue", "isOpen"],

    components: { 
        WsaTextField,
        "ui-title": UITitle,
        "close-icon": CloseIcon,
        "wsa-button": WsaButton
    },

    data: () => ({
        specialRequest: "" as string
    }),
    
    computed: {
        ...mapState("partner", ["appDataContentV2"]),
    },

    methods: {
        submitSpecialRequest() {
            this.$emit("onSpecialRequestSubmission", this.specialRequest);
        }
    },

    
   watch: {
    isOpen() {     
        this.specialRequest = this.specialRequestValue;     
    }
   }
    
})
