
import Vue from 'vue'
import AppBar from "@/components/UI/AppBar/AppBar.vue"
import Footer from '@/components/Footer/Footer.vue'
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue"
import { Route } from 'vue-router';
import { RouteInformation, RouteMetaInterface } from '@/routerManagerNew/routes'
import { mapState } from 'vuex';
import AppDataContentV2 from '@/models/AppDataContentV2';

export default Vue.extend({
    components: {
        "app-bar": AppBar,
        "custom-footer": Footer, 
        "header-bar": HeaderBar,     
    },

    data: () => ({
        isLoading: false as  boolean,
        AppDataContentV2: AppDataContentV2
    }),

    computed: {

        ...mapState("partner", ["appDataContentV2", "partnerSettings"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("product", ["isProductDetailsLoading"]),
        
        pageTitle(): string {
            var routeName = this.route.name;            
            var pageTitle = "";

            switch(routeName) {
                case RouteInformation.Checkout: 
                    pageTitle = this.appDataContentV2.CheckoutPageTitleText;
                    break;

                case RouteInformation.OrdersPage: 
                    pageTitle = this.appDataContentV2.OrdersPageTitle;
                    break;

                case RouteInformation.OrderSummaryPage: 
                    pageTitle = this.AppDataContentV2.replacePlaceholder(this.appDataContentV2.OrderSummaryPageTitle, this.route.params["orderId"]);
                    break;

                case RouteInformation.ProfilePage: 
                    pageTitle = this.appDataContentV2.AccountDetailsPageTitle;
                    break;

                case RouteInformation.WalletPage: 
                    pageTitle =  this.appDataContentV2.WalletPageTitle;
                    break;

                case RouteInformation.LoyaltyPage: 
                    pageTitle = this.AppDataContentV2.replacePlaceholder(this.appDataContentV2.LoyaltyPageTitle, this.partnerSettings.partnerName)
                     
                    break;

                case RouteInformation.ContactUsPage: 
                    pageTitle =  "Contact Us";
                    break;
            }

            return pageTitle;


        },



        // get the route
        route(): Route {
            return this.$route;
        },

        // check if route has app bar
        isAppBarDisplayed(): boolean{            
            if(this.isMobileViewDisplayed == true && (this.$route.name == "storesPage" ||this.$route.name == "storeHomePage")) {
                return true;
            }

            return (this.route.meta as RouteMetaInterface).includeAppBar;
        },

        // check if route has light app bar
        isAppBarLight(): boolean{
            return (this.route.meta as RouteMetaInterface).hasLightAppBar;
        },

        // check if footer is displayed
        isFooterDisplayed(): boolean{
            return (this.route.meta as RouteMetaInterface).includeFooter;
        }
    },

    methods: {
        updateLoadingState(val: boolean) {
            this.isLoading = val;
        }
    },

    watch: {
        isProductDetailsLoading(val: boolean) {
            this.updateLoadingState(val)
        } 
    }
    
})
