import partnerModule, { PartnerSettingsState } from "@/store/modules/partnerModule";

export default class UserGender {
    genderInt: number = 0;
    genderStr: string = ""; 


    constructor(genderInt: number, genderStr: string) {
        this.genderInt = genderInt;
        this.genderStr = genderStr;
    }

    // the String definition of each
    static genderNoneStr: string = "";
    static genderMaleStr: string = "";
    static genderFemaleStr: string = "";

    // User defined gender
    static nullValue: UserGender = new UserGender(-1, "null");
    static male: UserGender = new UserGender(1, typeof (partnerModule.state as PartnerSettingsState).appDataContentV2.MaleGenderText == 'undefined' ? "Male" : (partnerModule.state as PartnerSettingsState).appDataContentV2.MaleGenderText );
    static female: UserGender = new UserGender(2, typeof (partnerModule.state as PartnerSettingsState).appDataContentV2.FemaleGenderText == 'undefined' ? "Female" : (partnerModule.state as PartnerSettingsState).appDataContentV2.FemaleGenderText);
    static noneGender: UserGender = new UserGender(0, typeof (partnerModule.state as PartnerSettingsState).appDataContentV2.UnknownGenderText == 'undefined' ? "None" : (partnerModule.state as PartnerSettingsState).appDataContentV2.UnknownGenderText);
    
    // List of genders
    static listOfGenders = [UserGender.noneGender, UserGender.male, UserGender.female];

    // TO GET THE RELEVANT GENDER FROM INT
    static getGenderFromInt(genderInt: number): UserGender{
        if(genderInt == 0) return UserGender.noneGender;
        else if(genderInt == 1) return UserGender.male;
        else if(genderInt == 2) return UserGender.female;
        return UserGender.noneGender;
    }
}