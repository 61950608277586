
import CategoryCard from '@/components/Categories/CategoryCard.vue';
import SubcategoryCard from '@/components/Categories/SubcategoryCard.vue';
import StoreSettings from '@/models/StoreSettings';
import routerManager from '@/routerManagerNew/routerManager'
import ProductsPage from '@/views/v1/ProductsPages/ProductsPage.vue';
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import CloseIcon from '../Icons/CloseIcon.vue';
import InitLoader from '../Loader/InitLoader.vue';
import UITitle from '../Text/UITitle.vue';
import SearchTextField from './SearchTextField.vue';
import AlcoholWarningDialog from "@/components/AlcoholWarningDialog.vue";
import TobaccoWarningDialog from "@/components/TobaccoWarningDialog.vue";

export default Vue.extend({
    components: {
        "search-text-field": SearchTextField,
        "products-page": ProductsPage,
        "ui-title": UITitle,
        "close-icon": CloseIcon,
        "subcategory-card": SubcategoryCard,
        CategoryCard,
        InitLoader,
        "alcohol-warning-dialog": AlcoholWarningDialog,
        "tobacco-warning-dialog": TobaccoWarningDialog
    },

    data: () => ({
        searchPattern: "" as string,
        isSearchFocused: false as boolean,
        subcatImageUrl: "https://suppystorage.blob.core.windows.net/valeur/subcategories",
        catImageUrl: "https://suppystorage.blob.core.windows.net/valeur/categories",
        loading: false as boolean,
        isSearchLoading: false as boolean,

        isCategoriesDisplayedInGetInspired: false as boolean,

        alcoholWarningDialog: false as boolean,
        tobaccoWarningDialog: false as boolean,
        selectedElement: {} as any
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("product", ["categoriesAndSubCatList", "departmentsList"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        
        router(): any {
            return this.$route
        },        
        // generate random subcategories
        randomSubcategories(): any[] {
            const numberOfElements: any[] = [];            
            var totalCategories = 8;
            
            // department is the highest layer
            if(((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isDepartmentsHigherStructure) 
            {
                if(this.departmentsList.length > 0 ) 
                {

                    if(this.departmentsList.length < totalCategories)
                        totalCategories = this.departmentsList.length;


                    while (numberOfElements.length < totalCategories) 
                    {
                        // return categories
                        const department = (this as any).departmentsList[Math.floor(Math.random() * (this as any).departmentsList.length)];

                        if(typeof department != "undefined" && department != null) {
                    
                            if (!numberOfElements.some((x) => x.id === department.id)) {                                
                                numberOfElements.push(department)
                            }

                        }             
                        this.isCategoriesDisplayedInGetInspired = true;
                    }
                } 
            }
            
            // categories is the highest layer
            else if(((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isCategoriesHigherStructure) 
            {
                if(this.categoriesAndSubCatList.length > 0) 
                {                  
                    if(this.categoriesAndSubCatList.length < totalCategories) 
                    {
                        totalCategories = this.categoriesAndSubCatList.length
                    }

                    while (numberOfElements.length < totalCategories) 
                    {

                        const category = this.categoriesAndSubCatList[Math.floor(Math.random() * this.categoriesAndSubCatList.length)];

                        if(typeof category != "undefined") {

                            // subcategory lowest layer 
                            if(((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isSubcategoriesLowestStructure) {

                                if (!numberOfElements.some((x) => x.id === category.id)) {
                                    numberOfElements.push(category)
                                }

                                this.isCategoriesDisplayedInGetInspired = true;
                                
                            }

                            // type lowest layer 
                            else {
                                const subcat = category.subcatList[Math.floor(Math.random() * category.subcatList.length)];

                                if (!numberOfElements.some((x) => x.id === subcat.id)) {
                                    subcat.categoryId = category.id;
                                    subcat.categoryName = category.name;
                                    numberOfElements.push(subcat);
                                }

                                this.isCategoriesDisplayedInGetInspired = false;
                            }
                        }
                    }

                }
            }

            return numberOfElements;
        }

    },

    methods: {
        ...mapActions("product", ["searchItems", "getItems"]),

        updateGetInspiredView() {
            if(this.searchPattern.length == 0) 
                this.isSearchFocused = true;
        },

        closeSearch() {
            this.searchPattern = "";
            this.isSearchFocused = false;
        },

        goToUrl(subcat: any) {

            if(((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isDepartmentsHigherStructure) { 

                    return {
                    
                        route:{
                            name: "categoriesPage",
                            params: {
                            departmentId: subcat.id,
                            departmentName: subcat.name,             
                            }
                        },

                        imageURL: subcat.isCustomCategory == true 
                            ? subcat.image
                            : (this as any).partnerSettings.blobUrl + `departments/${(this as any).partnerSettings.partnerId}/${subcat.image}`
                    }

            }

            else {
                if(((this as any).selectedStoreDetails as StoreSettings).categorizationAndStructureLayer.isSubcategoriesLowestStructure) {
                    return {
                    
                        route: {
                            name: "productsPageByCategory",
                            params: {
                                categoryId: subcat.id.toString(),
                                categoryName: subcat.name,
                                title: subcat.name
                            },
                            query: { 
                                type: subcat.isCustomCategory == true ? subcat.itemsCollectionType : 0,
                                typeId: subcat.isCustomCategory == true ? subcat.id : 0,
                            }
                        },

                        imageURL: subcat.isCustomCategory == true 
                            ? subcat.image
                            : `${this.catImageUrl}/${this.partnerSettings.partnerId}/${subcat.image}`
                    }
                }

                else {
                    return {
                        route: {
                            name: "productsPageBySubCategory",
                            params: {
                                categoryId: subcat.categoryId.toString(),
                                categoryName: subcat.categoryName,
                                subcategoryId:  subcat.id.toString(),
                                subcategoryName: subcat.name,
                                title: subcat.name
                            }
                        },

                        imageURL: `${this.subcatImageUrl}/${this.partnerSettings.partnerId}/${subcat.image}`
                    }          
                }
            }
        },



        goToElementPage(subcat: any, skipCheck: boolean = false) {
            if(skipCheck == false) {
                this.selectedElement = subcat;
                
                if(subcat.isAlcohol) {
                    this.alcoholWarningDialog = true;
                }

                else if(subcat.isTobacco == true) {
                    this.tobaccoWarningDialog = true;
                }
            }

            else {
                this.$router.push(this.goToUrl(subcat).route as any)
            }
        },

        searchItemsFunction() {
            if(this.searchPattern.trim().length >= 2) {                               
                routerManager.goToProductsBySearch(this.searchPattern)
                this.isSearchFocused = false;
            }
        }, 
    },

    watch: {
        "$route.path"() {            
            this.closeSearch();
        }
    }
})
