
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import StoreSettings from "@/models/StoreSettings";
import UISubtitle from "../UI/Text/UISubtitle.vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import MainDialogCard from "../MainDialogComponents/MainDialogCard.vue";
import CloseIcon from "../UI/Icons/CloseIcon.vue";
export default Vue.extend({
  props: ["items", "orderType", "selectedSlot", "address", "slotToCompare"],
  components: {
    "ui-title": UITitle,
    "ui-subtitle": UISubtitle,
    MainDialogCard,
    CloseIcon,
  },
  data: () => ({
    selectedDay: 0,
    slotsOfDay: [],
  }),
  computed: {
    ...mapState("schedule", ["availableSlots"]),
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("partner", ["appDataContentV2"]),


    chosenSlot: {
      get(): any {
        return this.selectedSlot;
      },
      set(value: any) {
        this.$emit("changed", value);
      },
    },
  },

  model: {
    prop: "selectedSlot",
    event: "changed",
  },

  methods: {
    ...mapActions("schedule", ["getAvailableSlots"]),

    slideNext() {
        let next = document.getElementById("schedule-days-next");
        next != null ? next.click() : null;
    },

    slidePrev() {
        let prev = document.getElementById("schedule-days-prev");
        prev?.click();
    },

    setChosenSlot(slot: any) {
      this.chosenSlot = slot;
      this.$emit("closeDialog");
    },

    moment(day: string): string {
      return moment(day).format("YYYY-MM-DD");
    },

    today(): string {
      return moment().format("YYYY-MM-DD");
    },

    async getStoreAvailableSlots() {
      await this.getAvailableSlots({
        branchId: (this.selectedStoreDetails as StoreSettings).id,
        orderType: this.orderType,
        items: this.items,       
        address: {
          city: this.address.city,
          area: this.address.area,
          tag: this.address.tag.id,
          coordinatesLongitude: this.address.position.longitude,
          coordinatesLatitude: this.address.position.latitude
        },
        userDeliveryZoneId: (this.selectedStoreDetails as StoreSettings).deliveryZone.id,
        userPickupZoneId: (this.selectedStoreDetails as StoreSettings).pickupZone.id,
        requestedFrom: 1
      })
    },

  },

  watch: {
    async orderType(newVal) {
      await this.getStoreAvailableSlots();
    },
  },
  async mounted() {
    await this.getStoreAvailableSlots();
  },
});
