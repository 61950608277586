
import OrderType from '@/models/OrderType';
import User from '@/models/User';
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex';
import UIMoneyFormat from "@/components/UI/Money/UIMoneyFormat.vue";
import { ImageSettings } from '@/Settings/Settings';
import CartActions from "@/components/Cart/CartActions/CartActions.vue";
import routerManager from '@/routerManagerNew/routerManager';
import StoreSettings from '@/models/StoreSettings';
import Authentication from "@/views/Authentication/Authentication.vue"
import { ERedirectTo } from '@/enums/ERedirectTo';
import EmptyCart from "@/components/Cart/EmptyCart.vue";
import PartnerSettings from '@/models/PartnerSettings';
import WsaButton from '../UI/Button/wsa-button.vue';
import { EStoreType } from '@/enums/EStoreType';
import AppDataContentV2 from '@/models/AppDataContentV2';

export default Vue.extend({
    name: "cart",

    components: {
        "money-formatter": UIMoneyFormat,
        "cart-actions": CartActions,
        "authentication": Authentication,
        EmptyCart,
        "wsa-button": WsaButton
    },

    props: ["cartDrawerState"],

    data: () => ({
        imageBaseUrl: ImageSettings.baseItemsUrl,
        authenticationDialog: false as boolean,
        redirectToCheckoutPageValue: ERedirectTo.Checkout,
        loading: false as boolean,
        deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt,
        size: '' as any,
        sectionSize: '' as any,
        AppDataContentV2: AppDataContentV2
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("auth", ["preferredOrderType"]),
        ...mapState("loading", ["serviceFeeLoader"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapGetters("auth", ["currentSelectedUser"]),
        ...mapGetters("cart", ["currentSelectedCart", "currentSelectedCartTotalAmount", "currentSelectedCartTotalQuantity"]),

        drawerState: {
            get(): boolean {
                return this.cartDrawerState
            },
            set(value: boolean) {
                this.$emit("changed", value);
            }
        },

        user(): User {
            return (this as any).currentSelectedUser as User
        },

        // get the current select store details
        selectedStoreDetailsInfo(): StoreSettings {
            return (this as any).selectedStoreDetails;
        },

        // get the diff between free delivery threshold and cart total amount
        diffBetweenFreeDeliveryThresholdAndTotal(): number {
        return this.getFreeDeliveryThreshold() - (this as any).currentSelectedCartTotalAmount;
        },

        isDeliveryOrderTypeSelected(): boolean {        
            return  this.preferredOrderType.typeInt == OrderType.deliveryOrderTypeInt
        },


        // the go to checkout btn will be disabled if:
        // 1- the chosen order type is unknown
        // 2- if the order type chosen is delivery and don't follow th delivery restrictions rules
        checkIfProceedBtnIsDisabled(): boolean {                        
            return this.preferredOrderType.typeInt == OrderType.unknowOrderTypeInt || this.preferredOrderType.typeInt == (this as any).deliveryOrderTypeInt 
                && this.cartDeliveryRestrictions() == true 
                && (this as any).currentSelectedCartTotalAmount < this.selectedStoreDetailsInfo.deliveryZone.deliveryThreshold;
        },

        
        isStoreIsConvenient(): boolean {
          return ((this as any).selectedStoreDetails as StoreSettings).storeType == EStoreType.Convenient;
        },
    },
    
    model: {
        prop: "cartDrawerState",
        event: "changed",
    },

    methods: {
        ...mapActions("order", ["getServiceFeeAmount"]),
         ...mapActions("eWallet", ["getEWallet"]),
         ...mapActions("loyalty", ["getLoyalty"]),

        cartDeliveryRestrictions(): boolean {
            if(this.selectedStoreDetailsInfo.deliveryZone.hasDeliveryRestrictions) {
                if(this.selectedStoreDetailsInfo.deliveryZone.deliveryThreshold > 0 && (this as any).currentSelectedCartTotalAmount < this.selectedStoreDetailsInfo.deliveryZone.deliveryThreshold) {
                    return true  
                }
                else {
                    return false          
                }
            }
            else {
                return false      
            }
        },

        formatAmount(amount: number): string {
            return amount.toLocaleString('en-US', {
                style: "currency",
                currency: this.selectedStoreDetails.defaultCurrency.currencyCode
            })
        },
    
        checkIfStoreHasFreeDeliveryFeature(): boolean {
            return ((this as any).partnerSettings as PartnerSettings).isDeliveryFeatureEnabled 
                && this.selectedStoreDetailsInfo.hasDeliveryService 
                && this.selectedStoreDetailsInfo.deliveryZone && (this as any).selectedStoreDetails.deliveryZone.hasFreeDelivery;
        },

        checkIfStoreHasFreeDeliveryRestrictions(): boolean {
            return this.checkIfStoreHasFreeDeliveryFeature() == true 
                && (this as any).selectedStoreDetails.deliveryZone.freeDeliveryThreshold != null 
                && (this as any).selectedStoreDetails.deliveryZone.freeDeliveryThreshold > 0
        },

        getFreeDeliveryThreshold(): number { 
            return this.checkIfStoreHasFreeDeliveryRestrictions() == true
            ? (this as any).selectedStoreDetails.deliveryZone.freeDeliveryThreshold
            : 0;
        },

        async goToCheckoutFlow() {
            this.loading = true;

            // get service fees
            await this.getServiceFeeAmount({
                basketValue: (this as any).currentSelectedCartTotalAmount,
                branchId: this.selectedStoreDetailsInfo.id,
                currencyCode: this.selectedStoreDetailsInfo.defaultCurrency.currencyCode
            });

            // if ewallet payment is enabled, get ewallet details
            if(((this as any).partnerSettings as PartnerSettings).isEWalletFeatureEnabled == true && ((this as any).partnerSettings as PartnerSettings).isEWalletPaymentFeatureEnabled == true)
                await this.getEWallet({currencyId: this.selectedStoreDetailsInfo.defaultCurrency.id });
            
            // if loyalty payment is enabled, get loyalty details
            if(((this as any).partnerSettings as PartnerSettings).isLoyaltyProgramEnabled == true && ((this as any).partnerSettings as PartnerSettings).isPartnerLoyaltyPaymentFeatureEnabled == true)
                await this.getLoyalty({CashCurrencyCode: this.selectedStoreDetailsInfo.defaultCurrency.currencyCode });

            this.drawerState = false;                               
            this.loading = false;
            routerManager.goToCheckoutPage();
        },

        async goToCheckout() {            
            if (typeof this.user.isUserLoggedIn == "undefined" || this.user.isUserLoggedIn == false ) {
                this.authenticationDialog = true;        
            }

            else {               
                await this.goToCheckoutFlow()
            }
        },
        calcHeights() {
            this.size = window.innerHeight
            // Button section
            let valueToExtract = 104
            
            // With info delivery banner
            if ((this.preferredOrderType.typeInt == this.deliveryOrderTypeInt) && (this.currentSelectedCartTotalQuantity > 0)) {
                if ((this.cartDeliveryRestrictions() == true) ||
                    (this.checkIfStoreHasFreeDeliveryFeature() == true) ||
                    (this.checkIfStoreHasFreeDeliveryRestrictions() == true && this.diffBetweenFreeDeliveryThresholdAndTotal > 0)) {
                    valueToExtract = valueToExtract + 182
                }
                else {
                    valueToExtract = valueToExtract + 154.5
                }
            }
            // Without
            else {
                valueToExtract = valueToExtract + 154.5
            }

            this.sectionSize = this.size - valueToExtract
        }
    },
    mounted() {
        this.calcHeights()
        addEventListener("resize", (event) => {
            this.calcHeights()
        })
    }
})
