
import PartnerSettings from '@/models/PartnerSettings';
import Vue from 'vue';
import { mapState } from 'vuex';
import countries from './countries';
import WsaTextField from "./WsaTextField.vue";
import phoneNumberMixin from "@/mixins/phoneNumberMixin"

export default Vue.extend({

    props: ['countryCode', 'phoneNumber', 'rules', 'countryCodeRules'],
    components: { "wsa-text-field": WsaTextField },

    mixins:[phoneNumberMixin],

    data: () => ({
        countries: countries as any,
        searchedCountries: [] as any,
        searchPattern: "" as string,
        countryCodeModel: "" as string
    }),

    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),

        phoneNumberModel: {
            get(): string {
                return this.phoneNumber as string;
            },

            set(value: string) {
                this.$emit("changed", value);
            }
        },

    },

    model: {
        event: "changed",
        prop: "phoneNumber"
    },

    methods: {
        setTheActualPhoneNumber() {
            var phoneNumber = this.phoneNumberModel.startsWith('0') == true ? this.phoneNumberModel.slice(1) : this.phoneNumberModel;
            this.$emit("actualPhoneNumber", this.countryCodeModel + phoneNumber.replaceAll('+', ''));
            this.$emit("selectedCountryCode", this.countryCodeModel);

        }
    },

    mounted() {
        this.countryCodeModel = ((this as any).partnerSettings as PartnerSettings).defaultPhoneNumberCode;
        this.$emit("selectedCountryCode", this.countryCodeModel);
    }

    
})
