import { Module } from "vuex";
import request, { _axios } from "@/apiManager/_requestHelper";
import { RootState } from "..";
import { Apis } from "@/apiManager/Apis";
import PartnerSettings from "@/models/PartnerSettings";
import AppDataContent from "@/models/AppDataContent";
import AppDataContentV2 from "@/models/AppDataContentV2";

export interface PartnerSettingsState{
    partnerSettings: PartnerSettings;
    appDataContent: AppDataContent;
    appDataContentV2: AppDataContentV2;
}

export default <Module<PartnerSettingsState, RootState>>{
    namespaced: true,
    state: {
        partnerSettings: {} as PartnerSettings,
        appDataContent: {} as AppDataContent,
        appDataContentV2: {} as AppDataContentV2,
    },

    mutations: {
        FETCH_PARTNER_SETTINGS(state, partnerSettings){
            state.partnerSettings = {} as PartnerSettings;
            state.partnerSettings = PartnerSettings.fetchPartnerSettingsObjectFromJson(partnerSettings.settings);
        },

        FETCH_PARTNER_DATA_CONTENT(state, appDataContent){
            // state.appDataContent = AppDataContent.fetchDataContentObjectFromJson(appDataContent);
            state.appDataContentV2 = AppDataContentV2.fetchAppDataContent(appDataContent);
        },
    },

    actions: {
        getPartnerSettings({commit}){
            return request({
                url: Apis.customerSettings,
                method: "POST",
                data: { buildNumber: 0},
                commit: commit,
                loaderKey: "partnerSettingsLoading",
                successCallback: (partnerSettings: any) => {
                    commit("FETCH_PARTNER_SETTINGS", partnerSettings);
                }          
            })
        },

        getPartnerDataContent({commit}) {
            return request({
                url: Apis.appDataContent,
                method: "GET",
                commit: commit,
                loaderKey: "appDataContentLoading",
                successCallback: (appDataContent: any) => {
                    commit("FETCH_PARTNER_DATA_CONTENT", appDataContent);
                }          
            })
        }
    }


}
  