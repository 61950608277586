
import AppDataContentV2 from '@/models/AppDataContentV2'
import Vue from 'vue'
import { mapState } from 'vuex'
import WsaButton from '../UI/Button/wsa-button.vue'
import UITitle from '../UI/Text/UITitle.vue'

export default Vue.extend({
    props: ["authenticationOtp", "onSubmit", "onResendBtnClick", "phoneNumber", "isLight"],

    data: () => ({
        smsRetryIn: 30,
        smsCounter: 1,
        interval: 0,
        contactUsDialog: false,
        AppDataContentV2: AppDataContentV2
    }),
    
    components: {    
        "ui-title": UITitle,
        "wsa-button": WsaButton,
    },  
    
    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),
        ...mapState("ui", ["isSmScreen"]),
        
        authenticationOtpModel: {
            get(): string {
                return this.authenticationOtp;
            },

            set(value: string) {
                this.$emit("changed", value);
            }
        }
    },

    model: {
        prop: "authenticationOtp",
        event: "changed"
    },

    methods: {
        openContactUsDialog() {
            this.$emit("setContactUsPage");
        },


        onResendBtnclicked() {                
            this.smsCounter++;
            this.smsRetryIn = this.partnerSettings.smsRetryAwaitingDelayInSeconds;
            clearInterval(this.interval);
            this.setTimeIntervalToResendSms();
            this.onResendBtnClick();
        },

        setTimeIntervalToResendSms() {
            this.interval = window.setInterval(() => {
                if(this.smsRetryIn == 0) {
                    clearInterval(this.interval);
                }
                else {
                    this.smsRetryIn--;
                }
            }, 1000)
        }
    },

    mounted() {
        this.smsRetryIn = this.partnerSettings.smsRetryAwaitingDelayInSeconds;
        this.setTimeIntervalToResendSms();
    }
})
