export default class Product {
    id: number = 0;
    itemId: number = 0;
    branchId: number = 0;
    orderItemId: number = 0;
    name: string = "";
    image: string = "";
    images: string[] = [];
    brandName: string = "";
    barcodes: Array<string> = [];
    subCategoryId: number = 0;
    subCategory: string = "";
    categoryId: number = 0;
    category: string = "";
    unitMeasureCode: string = "";
    unitMeasure: number = 0;
    unitMeasureDisplayOnApp: string = "";
    maxOrder: number = 0;
    price: number = 0;
    vat: number = 0;
    adjPrice: number = 0;
    adjPriceValue: number = 0;
    adjVATValue: number = 0;
    oldUnitPrice: number = 0;
    salesYear0: number = 0;
    salesYear1: number = 0;
    salesYear2: number = 0;
    salesYear3: number = 0;
    pointsPerc: number = 0;
    itemTypeId: number = 0;
    itemTypeName: string = "";
    hasAlternatives: boolean = false;
    isWeightSensitive: boolean = false;
    isTobacco: boolean = false;
    isAlcohol: boolean = false;
    promoType: number = 0;
    quantityInStock: number = 0;
    isOutOfStock: boolean = false;
    isProbablyOOS: boolean = false;
    isPromoted: boolean = false;
    promoPercentile: number = 0;
    promoExpiryDate: Date | null = new Date();
    isFeatured: boolean = false;
    heroTag: string = "";
    isFavorite: boolean = false;
    exclFromMOV: boolean = false;
    hasProductInformation: boolean = false;
    normalizedUoM: string | null = null;
    pricePerNormalizedUoM: number | null = null;
    drsValue: number  = 0;
  
    
    static fetchItemObjectFromJson(product: any) : Product {
        var item = {} as Product;

        item.id = product.id ;
        item.itemId = product.itemId ;
        item.branchId = product.branchId ;
        item.name = product.name ;
        item.image = product.images && product.images.length > 0 ? product.images[0] : product.image;
        item.images = product.images;
        item.brandName = product.brand;
        item.subCategoryId = product.subCategoryId;
        item.subCategory = product.subCategoryName;
        item.categoryId = product.categoryId;
        item.category = product.categoryName;
        item.unitMeasureCode = product.unitMeasureCode;
        item.unitMeasure = product.unitMeasureDescription;
        item.unitMeasureDisplayOnApp = product.unitMeasureDisplayOnApp;
        item.maxOrder = product.maxOrder;
        item.price = product.price;
        item.vat = product.vat ;
        item.adjPrice = product.adjPriceValue ;
        item.adjPriceValue = product.adjPriceValue ;
        item.adjVATValue = product.adjVATValue;
        item.oldUnitPrice = product.oldAdjPrice ;
       
        item.itemTypeId = product.itemTypeId;
        item.itemTypeName = product.itemTypeName;
        item.isWeightSensitive = product.isVariableWeight;
        item.isTobacco = product.isTobacco;
        item.isAlcohol = product.isAlcohol;
        item.promoType = product.promoType;
        item.quantityInStock = product.quantityInStock;
        item.isOutOfStock = product.isInventoryOOS;
        item.isProbablyOOS = product.isInventoryProbablyOOS;
        item.isPromoted = product.isPromoted;
        item.promoPercentile = product.promoPercentile;
        item.promoExpiryDate = product.expiryDate != null ? new Date(product.expiryDate) : null;
        item.isFeatured = product.isFeatured;
        item.isFavorite = product.isFavorite;
        item.exclFromMOV = product.exclFromMOV;
        item.hasProductInformation = product.hasProductInformation;
        item.normalizedUoM = product.normalizedUoM ?? null;
        item.pricePerNormalizedUoM = product.pricePerNormalizedUoM ?? null;
        item.drsValue = product.drsValue;


        return item;
    }


    static getItemsTypesFromItems(items: Product[]) : Array<string> {
        let types: Array<string> = [];

        items.forEach((item: Product) => {
            if(!types.includes(item.itemTypeName))
                types.push(item.itemTypeName);
        })

        return types;
    }

    static getItemsByTypeName(typeName: string, items: Product[]): Product[] {
        if(typeName == "All") 
            return items;
        
        return items.filter((item: Product) => item.itemTypeName == typeName);
    }

    static getItemsByTypeMapping(items: Product[], typeName: string): Map<string, Product[]> {
        var mapedItems: Map<string, Product[]> = new Map();
        
        if(typeName == 'All') {
            for (let i = 0; i < items.length; i++) {
                mapedItems.set(
                items[i].itemTypeName,
                items.filter((x: any) => {
                    if (x.itemTypeName == items[i].itemTypeName) return x;
                })
                );
            }
        } 
        else {
            for (let i = 0; i < items.length; i++) {
                if(items[i].itemTypeName == typeName){
                    mapedItems.set(
                    items[i].itemTypeName,
                    items.filter((x: any) => {
                        if (x.itemTypeName == items[i].itemTypeName) return x;
                    }));
                }
            }
        }

        return mapedItems;
    }
}

export enum WeightedProductUnitMeasureEnum {
    mg,
    g,
    kg
}

export class WeightedProductUnitMeasure{
    id: number = 0;
    weightedProductUnitMeasureEnum: WeightedProductUnitMeasureEnum = WeightedProductUnitMeasureEnum.g;
    displayString: string = "";

    /**
     *
     */
    constructor(id: number, weightedProductUnitMeasureEnum: WeightedProductUnitMeasureEnum, displayString: string) {
        this.id = id;
        this.weightedProductUnitMeasureEnum = weightedProductUnitMeasureEnum;
        this.displayString = displayString;
        
    }

    static weightedProductUnitMeasureInMg = new WeightedProductUnitMeasure(0, WeightedProductUnitMeasureEnum.mg, 'mg');
    static weightedProductUnitMeasureInG = new WeightedProductUnitMeasure(1, WeightedProductUnitMeasureEnum.g, 'g');
    static weightedProductUnitMeasureInKg = new WeightedProductUnitMeasure(2, WeightedProductUnitMeasureEnum.kg, 'kg');

    static getWeightedProductUnitMeasureFromId(id: number): WeightedProductUnitMeasure {
        switch(id) {
            case 0: return this.weightedProductUnitMeasureInMg;
            case 1: return this.weightedProductUnitMeasureInG;
            case 2: return this.weightedProductUnitMeasureInKg;
            default: return this.weightedProductUnitMeasureInG;
        }
    }

    static getWeightProductUnitMeasureFromString(unitMeasure: string): WeightedProductUnitMeasure {

        if(unitMeasure == this.weightedProductUnitMeasureInMg.displayString)
            return this.weightedProductUnitMeasureInMg;

        else if(unitMeasure == this.weightedProductUnitMeasureInG.displayString)
            return this.weightedProductUnitMeasureInG;

        else if(unitMeasure == this.weightedProductUnitMeasureInKg.displayString)
            return this.weightedProductUnitMeasureInKg;

        else return this.weightedProductUnitMeasureInG;
    }

    static formattedWeight(weight: number, unitMeasureCode: string): string {
        return weight + unitMeasureCode;
    }

    static getTotalWeightValueInCorrectUnit(value: number, unitMeasureCode: string): string {

        let unitMeasureId: number = this.getWeightProductUnitMeasureFromString(unitMeasureCode).id;


        while(value >= 1000) {
            value = value / 1000;
            unitMeasureId++; 
        }

        return this.formattedWeight(value, this.getWeightedProductUnitMeasureFromId(unitMeasureId).displayString);
    }
}

