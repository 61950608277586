
import Vue from "vue";
import AccountSettings from "@/components/Account/AccountSettings.vue";
import {  mapMutations, mapState } from "vuex";
import FirebaseAnalyticsScreensNames from "@/firebase/firebaseAnalyticsScreensNames";

export default Vue.extend({
  components: {
    AccountSettings,
  },

  data: () => ({    
    listItemChosen: 0,
  }),

  methods: {
    ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),
  },

  mounted() {
    // firebase analytics
    this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.profilePage);
  }
});
