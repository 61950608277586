
import Vue from "vue";
import UITitle from "@/components/UI/Text/UITitle.vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import User from "@/models/User";
import WsaButton from "../UI/Button/wsa-button.vue";
import GoogleMap from "./GoogleMap.vue";
import MainDialogCard from "../MainDialogComponents/MainDialogCard.vue";
import GooglePlacesAutocomplete from "@/components/Location/GooglePlacesAutocomplete.vue"
import CloseIcon from "@/components/UI/Icons/CloseIcon.vue"
import routerManager from "@/routerManagerNew/routerManager";

export default Vue.extend({
  props: {
    hideCancelButton: {
      required: false,
      default: false,
      type: Boolean,
    },

    fromStore: {
      required: false,
      default: false,
      type: Boolean
    },

    mapHeight: {
      required: false,
      type: String,      
    },

    setBorderRadius: {
      required: false,
      type: Boolean,      
    },

    fromAddressDialog: {
      required: false,
      type: Boolean,
      default: false      
    },

  },

  components: {
    "ui-title": UITitle,
    "ui-subtitle": UISubtitle,
    "wsa-button": WsaButton,
    "google-map": GoogleMap,
    "main-dialog-card": MainDialogCard,
    "auto-complete-location-search": GooglePlacesAutocomplete,
    "close-icon": CloseIcon,

  },

  data: () => ({
    selectedSearchedPlace: "",
    currentCenter: {lat:0, lng: 0} as any,

    debounce: 0,

    rule: [
      (v: any) =>
        (typeof v != "undefined" && v.trim() != "") || "This field is required",
    ],
    completeAddress: "" as string | undefined,
    isAutocompleteSearchActivated: false as boolean,
    addressLocationInfoLoading: false as boolean,
    mapRefObject: {} as any,
    updateMapDisplayView: false as boolean
  }),

  computed: {
    ...mapState("maps", ["address", "location"]),
    ...mapState("partner",["partnerSettings", "appDataContentV2"]),
    ...mapState("store",["selectedStoreDetails", "listOfStores", "listOfDeliveryStores", "listOfPickupStores"]),
    ...mapState("loading", ["locationInfoLoading", "listOfStoresLoading", "locationInfoLoading"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),

    ...mapGetters("auth", ["currentSelectedUser"]),
    ...mapGetters("cart", ["currentSelectedCart"]),

    user(): User {
      return (this as any).currentSelectedUser as User;
    },


  },

  methods: {
    ...mapActions("maps", ["getAddress"]),
    ...mapActions("store", [ "getStoreDetails"]),
    ...mapActions("address", ["createAddress", "getUserAddresses", "updateAddress"]),
    ...mapActions("order", ["getFirstAvailableSlots"]),

    ...mapMutations("loading", ["ENABLE_LOADING_STATE"]),
    ...mapMutations("auth",["ADD_LOCAL_ADDRESS", "SET_ORDER_TYPE"]),

    hideAutoCompleteSearch(){
      this.isAutocompleteSearchActivated = false;
    },

    // get location information of the selected location on map
    getLocationInformation() {
      if(typeof this.currentCenter.lat != "undefined")
      this.addressLocationInfoLoading = true;

        setTimeout(() => {
           this.getAddress({
            latitude: this.currentCenter.lat,
            longitude: this.currentCenter.lng,
          })
          .finally(() => {
              this.addressLocationInfoLoading = false;
          })
        }, 200);

    },


    setPlace(place: any) {
        if(place) { 
          this.updateMapDisplayView = true;
          this.currentCenter = {lat: parseFloat(place.lat), lng: parseFloat(place.lng)};  
          this.hideAutoCompleteSearch();
        }
    },

    async createOrUpdateAddress(address: any) {
      var adrObject = {
        tag: address.tag.id,
        tagDescription: address.tagDescription,
        streetNumber: address.streetNbre,
        streetName: address.streetName != null ? address.streetName : "",
        postalCode: address.postalcode,
        city: address.city,
        area: address.area,
        state: address.state,
        country: address.country,
        countryCode: address.countryCode,
        additionalAddressInformation: address.additionalAddressInfo,
        coordinatesLongitude: address.position.longitude,
        coordinatesLatitude: address.position.latitude,
        IsMultiAddressSA: true
      } as any;

      if(this.user.usedAddress.id && this.user.usedAddress.id > 0) {
        adrObject.id = this.user.usedAddress.id;
        await this.updateAddress({
          address: adrObject
        })
      }
      else {
        await this.createAddress({
          address: adrObject
        })
      }
    },

    // confirm location when user choose his location
    async confirmLocation() {
        this.addressLocationInfoLoading = true;


        // add address to the local storage
        this.ADD_LOCAL_ADDRESS({
          isDefault: false,
          addressInfo: (this as any).address,
          addressPosition: {lat: this.currentCenter.lat, lng: this.currentCenter.lng},
          additionalAddressInformation: this.completeAddress,
          addressId: this.user.usedAddress && this.user.usedAddress.id && this.user.usedAddress.id > 0 ? this.user.usedAddress.id : 0
        });

        if((this.user as User).isUserLoggedIn) {
          var userLocalAddress = (this.user as User).deliveryAddressesFromLocal[0];

          // create or update address
          await this.createOrUpdateAddress(userLocalAddress)
          .then(async () => {
            await this.getUserAddresses();
          })
        }

        routerManager.goToStoresPage();
        
        this.addressLocationInfoLoading = false;
        this.$emit('onAddressCreated');
    },

    validateAddressForm(): boolean {
      return (this.$refs as any).addressForm.validate();
    }

  },

  watch: {
      currentCenter() {
        this.ENABLE_LOADING_STATE("locationInfoLoading");
        // wait 0.5 sec before making request
        clearTimeout(this.debounce);
        this.debounce = setTimeout(this.getLocationInformation, 500);
    },
  },

  mounted() {
    if((this.user as User).usedAddress && (this.user as User).usedAddress.position && (this.user as User).usedAddress.position.latitude && (this.user as User).usedAddress.position.longitude) {
      this.currentCenter.lat = (this.user as User).usedAddress.position.latitude;
      this.currentCenter.lng = (this.user as User).usedAddress.position.longitude;
    }

    this.getLocationInformation();
    this.completeAddress = (this.user as User).usedAddress?.additionalAddressInfo
    this.$forceUpdate();
  }
});
