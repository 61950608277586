
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import UIMessageDialog from "@/components/UI/Popup/UIMessageDialog.vue";
import routerManager from '@/routerManagerNew/routerManager';
import User from '@/models/User';
import PartnerSettings from '@/models/PartnerSettings';
import Authentication from '@/views/Authentication/Authentication.vue'
import ContacUsCard from '@/views/Authentication/ContactUsCard.vue'
import FirebaseAnalyticsScreensNames from '@/firebase/firebaseAnalyticsScreensNames';
import CategoriesList from '@/components/Items/CategoriesListDrawer.vue'
import LocationMapCard from "@/components/Map/LocationCard.vue";
import WsaButton from '../../Button/wsa-button.vue';
import CloseIcon from '../../Icons/CloseIcon.vue';
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import AppDataContentV2 from '@/models/AppDataContentV2';

export default Vue.extend({

    components: {
        "ui-message-dialog": UIMessageDialog,
        "authentication": Authentication,
        "contact-us-card": ContacUsCard,
        "categories-list": CategoriesList,
        "location-map-card": LocationMapCard,
        "wsa-button": WsaButton,
        "close-icon": CloseIcon
    },


    props: ["profileSettingsDrawer"],

    data: () => ({
        itemClicked: 0,
        logoutDialog: false as boolean,
        ordersListDrawerState: false as boolean,
        authenticationDialog: false as boolean,
        contactUsDialog: false as boolean,
        addressDialog: false as boolean,
        AppDataContentV2: AppDataContentV2,
        authenticationComponentToDisplayFirst: -1       
    }),

    computed: {
        ...mapState("partner", ["appDataContent", "appDataContentV2", "partnerSettings"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        
        ...mapGetters("auth", ["currentSelectedUser"]),

        user(): User {
            return this.currentSelectedUser as User;
        },

        drawer: {
            get(): boolean {
                return this.profileSettingsDrawer;
            },

            set(value: boolean) {
                this.$emit("changed", value);
            }
        },

        isChangeStoreOptionDisplayed(): boolean {
            return this.isMobileViewDisplayed == true && (this.partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel
                && this.selectedStoreDetails && this.selectedStoreDetails.id > 0 && this.$route.name != 'storesPage'
        },  
        

        menuItems(): any[] {
            return [
                {text: this.appDataContentV2.MenuAccountText, icon: 'user.svg' ,to: {name: 'ProfilePage'}, divider: false, condition: (this.user as User).isUserLoggedIn},
                {text: this.appDataContentV2.MenuOrdersListText, icon: 'orders.svg',to: {name: 'OrdersPage'}, divider: true, condition: (this.user as User).isUserLoggedIn},                
                {text: this.appDataContentV2.MenuWalletText, icon: 'wallet.svg', to: {name: 'WalletPage'}, divider: true, condition: (this.partnerSettings as PartnerSettings).isEWalletFeatureEnabled},                
                {text: this.appDataContentV2.MenuLoyaltyText, icon: 'loyalty-1.svg', to: {name: 'LoyaltyPage'}, divider: true, condition: (this.partnerSettings as PartnerSettings).isLoyaltyProgramEnabled},                
            ]
        },
    },

    model: {
        prop: "profileSettingsDrawer",
        event: "changed"
    },

    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapMutations("product", ["SET_LAST_SUBCAT_SELECTED", "SET_LAST_CAT_SELECTED"]),
        ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),

        onRegistrationAuthenticationClick() {
            this.authenticationComponentToDisplayFirst = 1;
            this.drawer = false;
            this.authenticationDialog = true;
        },

        onLoginAuthenticationClick() {
            this.authenticationComponentToDisplayFirst = 0;
            this.drawer = false;
            this.authenticationDialog = true;
        },
              
        onItemClicked(item: number) {
            this.itemClicked = item;

            switch(item) {
                case 3:
                    this.drawer = false;
                    routerManager.goToWalletPage();
                    break;                
                
                case 4:
                    this.drawer = false;
                    routerManager.goToLoyaltyPage();
                    break;                
            }
        },

        logoutFunction() {
            this.logout();
            this.logoutDialog = false;
        },

        onLogout() {
            this.logoutDialog = true;
            this.drawer = false;
        },

        onLoginBtnPressed() {
            this.drawer = false;
            this.authenticationDialog = true;
        },

        onAppStoreBtnPressed() {
            var anchor = document.createElement('a');
            anchor.target="_blank"
            anchor.href = (this.partnerSettings as PartnerSettings).msaAppStoreUrl;
            anchor.click();
        },

        onGooglePlayBtnPressed() {
            var anchor = document.createElement('a');
            anchor.target="_blank"
            anchor.href = (this.partnerSettings as PartnerSettings).msaGooglePlayUrl;
            anchor.click();
        },

        onCloseClick() {
            this.drawer = false;
        }
    },

    watch: {
        drawer(value: boolean) {
            if(value == true) {
                // firebase analytics
                this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.settingsPage);
            }
        }
    }
})
