
import Vue from 'vue'
import InitLoader from '@/components/UI/Loader/InitLoader.vue'
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import PartnerSettings from '@/models/PartnerSettings';
import GoogleMap from "@/components/Map/GoogleMap.vue";

import { mapGetters, mapState } from 'vuex';
import User from '@/models/User';
import Store from '@/models/Store';
import StoreService from '@/services/storeService';
import routerManager from '@/routerManagerNew/routerManager';
import { ETypeOfBranchServices } from '@/models/ETypeOfBranchServices';
import EmptyView from "@/components/Cart/EmptyCart.vue";
import LocationMapCard from "@/components/Map/LocationCard.vue";
import StoreCard from '@/components/Store/StoreCard.vue'
import _requestHelper from '@/apiManager/_requestHelper';
import { Apis } from '@/apiManager/Apis';
import CloseIcon from '@/components/UI/Icons/CloseIcon.vue';
import AppDataContentV2 from '@/models/AppDataContentV2';

export default Vue.extend({
    components: {
        "init-loader": InitLoader,
        "google-map": GoogleMap,
        "store-card": StoreCard,
        "empty-view": EmptyView,
        "location-map-card": LocationMapCard,
        CloseIcon
    },

    props: {
        openedFromDialog: {
            default: false,
            type: Boolean,
            required: false
        },

        isDiaologOpened: {
            default: false,
            type: Boolean,
            required: false
        }                
    },

    data: () => ({
        loading: false as boolean,
        isFromLandingPage: false as boolean,
        isPageIsReloaded: false as boolean,
        isFromShopPages: false as boolean,
        hoveredStoreId: 0 as number,
        addressDialogState: false as boolean,
        listOfEligibleStores: [] as Store[],
        AppDataContentV2: AppDataContentV2,
    }),

    computed: {
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),
        ...mapState("auth", ["preferredOrderType"]),
        ...mapState("store", ["listOfStores", "listOfDeliveryStores", "selectedStoreDetails"]),
        
        ...mapGetters("auth", ["currentSelectedUser"]),

        /** Define user object */
        user(): User {
            return (this as any).currentSelectedUser as User;
        },
        
        /** Check if the partner structure type is agnostic */
        isPartnerStoreStructureTypeAgnostic(): boolean {
            return ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel; 
        },

        /** Check if the partner structure type is single store */
        isPartnerStoreStructureTypeSingleStore(): boolean {
            return ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel; 
        },
        

        /** Check if the initial loader page is displayed */
        isInitialLoaderPageDisplayed(): boolean {
            return (this.isPartnerStoreStructureTypeAgnostic == true || this.isPartnerStoreStructureTypeSingleStore == true) && this.loading == true
        },


        /** Retrieve the markers to be displayed on map of each available branch */
        getStoresPositionsAndMarkersUrl(): any {           
            return ((this as any).listOfEligibleStores as Store[]).map(store => {                
                return {
                id: store.id,
                lat: store.address.position.latitude,
                lng: store.address.position.longitude,
                marker: store.mapMarkerImageUrl
                }
            })                   
        },

        routeReloadQuery(): string {
            return this.$route.query["_r"] as string; 
        }

    },

    methods: {

        /** Set a store selected by the user */
        setStore(storeId: number) {
            StoreService.getStoreDetails(storeId, this.isPartnerStoreStructureTypeAgnostic).then(() => {
                // go to the store home page
                routerManager.goToStoreHomePage((this as any).selectedStoreDetails as Store);
                this.$emit('closeDialog');
            })
        },

        /** get list of elligible branches */
        async getListBranches() {

            // update loading state            
            this.loading = true;

            //#region update the type of branches that needs to be fetched            
            var typeOfBranchServices = ETypeOfBranchServices.All;

            // if the partner store structure type is agnostic
            // check the previous route:
            //      if the route was the landing page, get all type of branch services
            //      else if the route is from the store home page, get only the pickup stores
            if(this.isPartnerStoreStructureTypeAgnostic == true) {

                if(this.openedFromDialog == true) {
                    typeOfBranchServices = ETypeOfBranchServices.PickupOnly;
                }        
            }

            if(typeOfBranchServices == ETypeOfBranchServices.All) {
                await StoreService.getElligibleStores();
                this.listOfEligibleStores = (this as any).listOfStores;
            }

            else {
                // get list of stores           
                var storeObject = new Store();
                await _requestHelper({
                    method: "post",
                    url: Apis.listOfDiffrentStoresURL,
                    data: {
                        shopperCoordinates: {
                            latitude: this.user.usedAddress.position.latitude,
                            longitude: this.user.usedAddress.position.longitude,
                        },
                        typeOfBranchServices: typeOfBranchServices
                    },
                    loaderKey: "",
                    successCallback: async (stores: any) => {
                        this.listOfEligibleStores = stores.map((store:any) => storeObject.fetchStoreObjectFromJson(store));
                    }
                })
            }

            if(this.listOfEligibleStores.length > 0) {
                if(typeOfBranchServices == ETypeOfBranchServices.All) {

                    // if the partner store structure type is agnostic
                    // get the first delivery branch, get it's details
                    if(this.isPartnerStoreStructureTypeAgnostic == true ) {  
                        var listOfDeliveryStores = this.listOfEligibleStores.filter(store => StoreService.checkIfStoreIsEligibileForDelivery(store));

                        if(listOfDeliveryStores != null && listOfDeliveryStores.length > 0) {
                            await StoreService.getStoreDetails(listOfDeliveryStores[0].id);      

                            // go to store home page
                            routerManager.goToStoreHomePage(listOfDeliveryStores[0]);
                           
                        }
                    }

                    // if the partner store structure is single store
                    // go to the first available store
                    else {
                        if(this.isPartnerStoreStructureTypeSingleStore == true) {
                            await StoreService.getStoreDetails(this.listOfEligibleStores[0].id);      
                            routerManager.goToStoreHomePage(this.listOfEligibleStores[0]);
                        }
                    }
                }
            }
            
            // update loading state
            this.addressDialogState = false;        
            this.loading = false; 
        },

        closeStoresDialog() {
            this.$emit("closeDialog")
        }

    },

    async mounted() {
        await this.getListBranches();
    },

    watch: {
        async isDiaologOpened(val: boolean) {
            if(val == true) {
                await this.getListBranches();
            }
        },

        async routeReloadQuery() { 
            await this.getListBranches();
        }
    }
})
