import { Route, RouteConfig } from "vue-router";
import LandingPage from "@/views/LandingPage.vue";
import ProfilePage from "@/views/Profile/Profile.vue";
import PageContainer from "@/views/v1/PageContainer.vue";
import ProductsPageContainer from "@/views/v1/ProductsPages/ProductsPageContainer.vue";
import ProductsPage from "@/views/v1/ProductsPages/ProductsPage.vue";
import StoresPage from "@/views/Stores/Stores.vue";
import StoreHomePage from "@/views/StorePages/ProductsPages/StoreHomePage.vue";
import ProductDetailsPage from "@/views/v1/ProductsPages/ProductDetails.vue";
import CheckoutPage from "@/views/StorePages/Orders/Checkout.vue"
import OrdersPage from "@/views/StorePages/Orders/OrdersPage.vue"
import OrderSummaryPage from "@/views/StorePages/Orders/OrderSummary.vue"
import WalletPage from "@/views/Profile/Wallet.vue"
import LoyaltyPage from "@/views/Profile/LoyaltyProgram.vue"
import DeleteAccount from "@/views/Profile/DeleteAccount.vue"
import SubcategoriesPage from "@/views/v1/ProductsPages/SubcategoriesPage.vue"
import CategoriesPage from "@/views/v1/ProductsPages/CategoriesPage.vue"
import NotFoundPage from "@/views/404.vue"
import DynamicDeepLink from "@/views/v1/ProductsPages/DynamicDeepLink.vue";


export enum EProductsPageType {
    byCategory,
    bySubcategory,
    bySection,
    byCollection,
    bySearch,
    bySingleProduct
}


export interface RouteMetaInterface {
    isStatic: boolean; // define if the page should proceed with the normal flow of the WSA or not
    requiresAuth: boolean; // if set to true, the page requires the user to be logged in
    includeFooter: boolean;
    includeAppBar: boolean;
    hasLightAppBar: boolean;
    deepLink?: boolean;
    productsPageType?: EProductsPageType;
    breadCrumb?: any;
}

export interface RouteInterface {
    name: string,
    path: string,
    props: boolean;
    component?: any,
    meta?: RouteMetaInterface,
    children?: Array<RouteInterface>
    // firebaseScreenName: string
}

export class RouteInformation {
    public static NotFound: string = "NotFound";
    public static landingPage: string = "landingPage";
    public static ProfilePage: string = "ProfilePage";
    public static WalletPage: string = "WalletPage";
    public static LoyaltyPage: string = "LoyaltyPage";
    public static DeleteAccountPage: string = "DeleteAccountPage";
    public static storesPage: string = "storesPage";
    public static storeHomePage: string = "storeHomePage";
    public static productsPageByCategory: string = "productsPageByCategory";
    public static productsPageByCategoryByDepartment: string = "productsPageByCategoryByDepartment";
    public static categoriesPage: string = "categoriesPage";
    public static productsPageBySubCategory: string = "productsPageBySubCategory";
    public static productsPageBySearch: string = "productsPageBySearch";
    public static productsBySection: string = "productsBySection";
    public static productsPageByCampaign: string = "productsPageByCampaign";
    public static productDetailsPage: string = "productDetailsPage";
    public static subcategoriesPage: string = "subcategoriesPage";
    public static Checkout: string = "Checkout";
    public static OrdersPage: string = "OrdersPage";
    public static OrderSummaryPage: string = "OrderSummaryPage";
    public static ContactUsPage: string = "ContactUsPage";
}

export class Routes {
    

    static notFound: RouteInterface = {
        name: "NotFound",
        path: "*",
        props: false,
        component: NotFoundPage,
    }

    // Landing Page
    static index: RouteInterface = 
    {
        name: "landingPage", 
        path: "/",
        props: false,
        meta: {
            isStatic: true,
            requiresAuth: false,
            includeFooter: false,
            includeAppBar: false,
            hasLightAppBar: false
        },       
        component: LandingPage,
    };  
    
    
    static support: RouteInterface = {
        name: "SupportPages",
        path: "/support",
        props: false,
        component: PageContainer,
        children: [
            // {
            //     name: RouteInformation.ContactUsPage,
            //     path: "contact-us",
            //     props: false,
            //     component: ContactUsPage,
            //     meta: {
            //         isStatic: true,
            //         requiresAuth: false,
            //         includeFooter: false,
            //         includeAppBar: true,
            //         hasLightAppBar: true
            //     },
            // },
        ]
    };

    // User Pages
    static userPages: RouteInterface =
    {
        name: "UsersPage",
        path: "/user",
        props: false,
        component: PageContainer,
        children: [
            {
                name: RouteInformation.ProfilePage,
                path: "profile",
                props: false,
                component: ProfilePage,
                meta: {
                    isStatic: true,
                    requiresAuth: true,
                    includeFooter: false,
                    includeAppBar: true,
                    hasLightAppBar: true
                },
            },
            {
                name: RouteInformation.WalletPage,
                path: "wallet",
                props: false,
                component: WalletPage,
                meta: {
                    isStatic: true,
                    requiresAuth: true,
                    includeFooter: false,
                    includeAppBar: true,
                    hasLightAppBar: true
                },
            },
            {
                name: RouteInformation.LoyaltyPage,
                path: "loyalty",
                props: false,
                component: LoyaltyPage,
                meta: {
                    isStatic: true,
                    requiresAuth: true,
                    includeFooter: false,
                    includeAppBar: true,
                    hasLightAppBar: true
                },
            },
        ]
    };

    static userDeletePage: RouteInterface =
    {
                
        name: RouteInformation.DeleteAccountPage,
        path: "/user/delete",
        props: false,
        meta: {
            isStatic: true,
            requiresAuth: false, 
            includeFooter: false,
            includeAppBar: true,
            hasLightAppBar: true           
        },
        component: DeleteAccount,
        children: [],    
        
    };
    

    static storesPage: RouteInterface = {
        name: "shopPages", 
        path: "/shop",
        props: true,
        component: PageContainer,
        children: [

            {
                name: RouteInformation.storesPage,
                path: "stores",
                props: true,
                component: StoresPage,
                meta: {
                    isStatic: false,
                    requiresAuth: false,
                    includeFooter: false,
                    includeAppBar: false,
                    hasLightAppBar: false
                },
            },

            {
                name: "StorePage",
                path: "stores/:storeId/:storeDisplayName",
                props: true,
                component: ProductsPageContainer,
                meta: {
                    isStatic: false,
                    requiresAuth: false,
                    includeFooter: true,
                    includeAppBar: true,
                    hasLightAppBar: true
                },
                children: [

                    // Store Home Pages
                    {
                        name: RouteInformation.storeHomePage,
                        path: "home",
                        props: true,
                        component: StoreHomePage,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false
                        },
                    },
                    
                    // categories Page by department
                    {
                        name: RouteInformation.categoriesPage,
                        path: "departments/:departmentId/:departmentName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["departmentName"],
                                    },
                                ]
                            }   
                        },
                        component: CategoriesPage,
                    },


                    // Store Subcategories Page by category
                    {
                        name: RouteInformation.subcategoriesPage,
                        path: "categories/:categoryId/:categoryName/list",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["categoryName"],                                    
                                    },
                                ]
                            }   
                        },
                        component: SubcategoriesPage,
                    },

                    // Store Products By Category by departments Page 
                    {
                        name: RouteInformation.productsPageByCategoryByDepartment,
                        path: "departments/:departmentId/:departmentName/categories/:categoryId/:categoryName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.byCategory,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;
                                
                                return  [                                    
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["departmentName"],
                                        to: {
                                            name: RouteInformation.categoriesPage,
                                            params: {
                                                departmentId: paramsFromRoute["departmentId"],
                                                departmentName: paramsFromRoute["departmentName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["categoryName"]
                                    },
                                ]
                            } 
                        },
                        component: ProductsPage,
                    },

                    // Store Products By Category Page
                    {
                        name: RouteInformation.productsPageByCategory,
                        path: "categories/:categoryId/:categoryName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.byCategory,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [                                    
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["categoryName"]
                                    },
                                ]
                            } 
                        },
                        component: ProductsPage,
                    },

                    // Store Products By SubCategory Page
                    {
                        name: RouteInformation.productsPageBySubCategory,
                        path: "categories/:categoryId/:categoryName/:subcategoryId/:subcategoryName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.bySubcategory,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [       
                                    {
                                        text: "Home",
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["categoryName"],
                                        to: {
                                            name: RouteInformation.subcategoriesPage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                                categoryId: paramsFromRoute["categoryId"],
                                                categoryName: paramsFromRoute["categoryName"]
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["subcategoryName"]
                                    }
                                ]
                            } 
                        },
                        component: ProductsPage,
                    },

                    // Store Product Details
                    {
                        name: RouteInformation.productDetailsPage,
                        path: "products/:productId/:productName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.bySingleProduct,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [
                                    {
                                        text: "Home",
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["productName"]
                                    }                                    
                                ]
                            } 
                        },
                        component: ProductDetailsPage,
                    },

                    // Store Products By Search Page
                    {
                        name: RouteInformation.productsPageBySearch,
                        path: "search",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.bySearch,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;
                                var searchQuery = route.query["q"];

                                return  [
                                    {
                                        text: "Home",
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: searchQuery
                                    }                                    
                                ]
                            } 
                        },
                        component: ProductsPage
                    },                

                    // Store Products By Section Page
                    {
                        name: RouteInformation.productsBySection,
                        path: "section/:sectionId/:section",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.bySection,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [                                    
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["section"]
                                    },
                                ]
                            } 

                        },
                        component: ProductsPage
                    },


                    // Store Products By Campaign Page
                    {
                        name: RouteInformation.productsPageByCampaign,
                        path: "campaign/:campaignId/:campaignName",
                        props: true,
                        meta: {
                            isStatic: false,
                            requiresAuth: false,
                            includeFooter: true,
                            includeAppBar: true,
                            hasLightAppBar: false,
                            productsPageType: EProductsPageType.byCollection,
                            breadCrumb(route: Route): any[] {
                                var paramsFromRoute = route.params;

                                return  [                                    
                                    {
                                        text: 'Home',
                                        to: {
                                            name: RouteInformation.storeHomePage,
                                            params: {
                                                storeId: paramsFromRoute["storeId"],
                                                storeDisplayName: paramsFromRoute["storeDisplayName"],
                                            }
                                        },
                                    },
                                    {
                                        text: paramsFromRoute["campaignName"]
                                    },
                                ]
                            } 
                        },
                        component: ProductsPage

                    },


                ]
                

            }                                                   
        ],

        
    };

    static ordersPage: RouteInterface = 
        // Orders Pages
        {                
            name: "Orders",
            path: "/orders",
            props: false,
            component: PageContainer,
            meta: {
                isStatic: false,  
                requiresAuth: true,
                includeFooter: false,
                includeAppBar: true,
                hasLightAppBar: true                
            },

            children: [
                // Checkout Page
                {
                    name: RouteInformation.Checkout,
                    path: "checkout",
                    props: false,
                    meta: {
                        isStatic: false,
                        requiresAuth: true,
                        includeFooter: false,
                        includeAppBar: true,
                        hasLightAppBar: true  
                    },
                    component: CheckoutPage
                },
                {
                    name: RouteInformation.OrdersPage,
                    path: "list",
                    props: false,
                    meta: {
                        isStatic: true,
                        requiresAuth: true,
                        includeFooter: false,
                        includeAppBar: true,
                        hasLightAppBar: true  
                    },
                    component: OrdersPage,
                },
                {
                    name: RouteInformation.OrderSummaryPage,
                    path: ":orderId",
                    props: true,
                    meta: {
                        isStatic: true,
                        requiresAuth: true,
                        includeFooter: false,
                        includeAppBar: true,
                        hasLightAppBar: true  
                    },
                    component: OrderSummaryPage
                }                     
            ]
    };

    static deepLink: RouteInterface = 
    // Orders Pages
    {                
        name: "ProductDeepLink",
        path: "/products/:productId/:productName",
        props: true,
        component: DynamicDeepLink,
        meta: {
            isStatic: false,  
            requiresAuth: false,
            includeFooter: false,
            includeAppBar: false,
            hasLightAppBar: false,
            deepLink: true
        },
        children: []
    };
}

function recursiveRoute(route: RouteInterface): RouteConfig {
    return {
        name: route.name,
        path: route.path,
        props: route.props,
        meta: route.meta,
        component: route.component ? route.component : NotFoundPage,
        children: route.children && route.children.length > 0 
                    ? route.children.map((child) => recursiveRoute(child)) : undefined,        
    }     
}


var routesToLoopThrough = [ Routes.index, Routes.userPages, Routes.userDeletePage, Routes.storesPage, Routes.ordersPage, Routes.notFound, Routes.support, Routes.deepLink ];

var routes: RouteConfig[];

routes = routesToLoopThrough.map(route => recursiveRoute(route));

export default routes;

