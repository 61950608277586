export default class AppDataContentV2 {

        
    
    WelcomeTitle: string = "";
    WelcomeMessage: string = "";
    ShopNowBtnText: string = "";
    AppStoreBtnText: string = "";
    GooglePlayBtnText: string = "";
        
    
    LocationDialogTitle: string = "";
    NoDetectedAddress: string = "";
    completeAddressTitle: string = "";
    completeAddressHint: string = "";
    searchBtnText: string = "";
    ConfirmAddressBtnText: string = "";
    searchAddressHint: string = "";

        
    
    SelectStoreText: string = "";
    NoEligibleStoresText: string = "";
    NoEligibleStoresSubText: string = "";
        
    
    StoreOpenStatus: string = "";
    StoreCloseStatus: string = "";
    StoreOpensTill: string = "";
    StoreOpensAt: string = "";
        
    
    ShopByCategoryText: string = "";
    ShopByBrandText: string = "";
    FeaturedProductsText: string = "";
    RecentlyAddedProductsText: string = "";
    PromotedProductsText: string = "";

        
    
    HomePageMenuText: string = "";

        
    
    SearchHintText: string = "";
    GetInspiredText: string = "";
    AllStoresText: string = "";
    CartSingleItem: string = "";
    CartMultiItem: string = "";

        
    
    DeliveryOrderTypeText: string = "";
    PickupOrderTypeText: string = "";
    NotAvailableOrderTypeText: string = "";

            
    
    CartTitleText: string = "";
    DeliveryToText: string = "";
    PickupFromText: string = "";
    CartEmptyViewText: string = "";
    CartMinimumOrderAmountForDeliveryText: string = "";
    CartMinimumOrderAmountForFreeDeliveryText: string = "";
    CartCongratsForFreeDeliveryText: string = "";

        
    
    RegisterBtnTxt: string = "";
    LoginBtnText: string = "";
    LogoutBtnText: string = "";
    ConfirmLogoutText: string = "";
    
            
    
    MenuChangeStoreText: string = "";
    MenuWelcomeMessage: string = "";
    MenuAccountText: string = "";
    MenuOrdersListText: string = "";
    MenuWalletText: string = "";
    MenuLoyaltyText: string = "";
    MenuIPhoneText: string = "";
    MenuAndroidText: string = "";
    MenuMoreInAppText: string = "";
               
    
    AboutUsText: string = "";
    TermsAndConditionsText: string = "";
    PrivacyPolicyText: string = "";
        
    
    LoyaltyProgramTitle: string = "";
        
    
    AppStoreText: string = "";
    GooglePlayText: string = "";

        
    
    OrderCancelledStatusText: string = "";
    OrderScheduledStatusText: string = "";
    OrderNewStatusText: string = "";
    OrderOngoingStatusText: string = "";
    OrderAwaitingReplacementsStatusText: string = "";
    OrderReplacementsSelectedStatusText: string = "";
    OrderAlternativesTimeoutStatusText: string = "";
    OrderCollectionDoneStatusText: string = "";
    OrderReadyForDeliveryStatusText: string = "";
    OrderReadyForPickuStatusText: string = "";
    OrderInDeliveryStatusText: string = "";
    OrderDeliveredStatusText: string = "";
    OrderPickupDoneStatusText: string = "";
    OrderCompletedStatusText: string = "";

    OrderCancelledStatusDescription: string = "";
    OrderNewStatusDescription: string = "";
    OrderOngoingStatusDescription: string = "";
    OrderAwaitingReplacementStatusDescription: string = "";
    OrderReplacementsSelectedStatusDescription: string = "";
    OrderAlternativesTimeoutStatusDescription: string = "";
    OrderCollectionCompletedStatusDescription: string = "";
    OrderReadyForDeliveryStatusDescription: string = "";
    OrderReadyForPickupStatusDescription: string = "";
    OrderInDeliveryStatusDescription: string = "";
    OrderDeliveredStatusDescription: string = "";
    OrderPickupDoneStatusDescription: string = "";
    OrderCompletedStatusDescription: string = "";

    ongoingGrpStatus: string = "";
    completedGrpStatus: string = "";
    cancelledGrpStatus: string = "";
        
    
    OrdersPageEmptyViewText: string = "";

        
    
    UnknownGenderText: string = "";
    MaleGenderText: string = "";
    FemaleGenderText: string = "";

        
    
    PaymentMethodUnknownText: string = "";
    PaymentMethodCashText: string = "";
    PaymentMethodCashOnDeliveryDescriptionText: string = "";
    PaymentMethodCashOnPickupDescriptionText: string = "";
    PaymentMethodCardText: string = "";
    PaymentMethodCardDescriptionOnDeliveryText: string = "";
    PaymentMethodCardDescriptionOnPickupText: string = "";
    PaymentMethodOnlinePaymentText: string = "";
    PaymentMethodOnlinePaymentDescriptionText: string = "";

        
    
    OrderTypeUnknownTitle: string = "";
    OrderTypeDeliveryTitle: string = "";
    OrderTypePickupTitle: string = "";
    OrderTypeInStoreTitle: string = "";
    OrderTypeOtherTitle: string = "";



    static fetchAppDataContent(dataContent: any): AppDataContentV2 {
        return {
            WelcomeTitle: dataContent.WelcomeTitle,
            WelcomeMessage: dataContent.WelcomeMessage,
            ShopNowBtnText: dataContent.ShopNowBtnText,
            AppStoreBtnText: dataContent.AppStoreBtnText,
            GooglePlayBtnText: dataContent.GooglePlayBtnText,
            LocationDialogTitle: dataContent.LocationDialogTitle,
            NoDetectedAddress: dataContent.NoDetectedAddress,
            completeAddressTitle: dataContent.completeAddressTitle,
            completeAddressHint: dataContent.completeAddressHint,
            searchBtnText: dataContent.searchBtnText,
            ConfirmAddressBtnText: dataContent.ConfirmAddressBtnText,
            searchAddressHint: dataContent.searchAddressHint,
            SelectStoreText: dataContent.SelectStoreText,
            NoEligibleStoresSubText: dataContent.NoEligibleStoresSubText,
            NoEligibleStoresText: dataContent.NoEligibleStoresText,
            ChangeAddressBtnTxt: dataContent.ChangeAddressBtnTxt,
            StoreOpenStatus: dataContent.StoreOpenStatus,
            StoreCloseStatus: dataContent.StoreCloseStatus,
            StoreOpensTill: dataContent.StoreOpensTill,
            StoreOpensAt: dataContent.StoreOpensAt,
            ShopByCategoryText: dataContent.ShopByCategoryText,
            ShopByBrandText: dataContent.ShopByBrandText,
            FeaturedProductsText: dataContent.FeaturedProductsText,
            RecentlyAddedProductsText: dataContent.RecentlyAddedProductsText,
            PromotedProductsText: dataContent.PromotedProductsText,
            HomePageMenuText: dataContent.HomePageMenuText,
            SearchHintText: dataContent.SearchHintText,
            GetInspiredText: dataContent.GetInspiredText,
            AllStoresText: dataContent.AllStoresText,
            CartSingleItem: dataContent.CartSingleItem,
            CartMultiItem: dataContent.CartMultiItem,
            DeliveryOrderTypeText: dataContent.DeliveryOrderTypeText,
            PickupOrderTypeText: dataContent.PickupOrderTypeText,
            NotAvailableOrderTypeText: dataContent.NotAvailableOrderTypeText,
            CartTitleText: dataContent.CartTitleText,
            DeliveryToText: dataContent.DeliveryToText,
            PickupFromText: dataContent.PickupFromText,
            CartEmptyViewText: dataContent.CartEmptyViewText,
            CartMinimumOrderAmountForDeliveryText: dataContent.CartMinimumOrderAmountForDeliveryText,
            CartMinimumOrderAmountForFreeDeliveryText: dataContent.CartMinimumOrderAmountForFreeDeliveryText,
            CartCongratsForFreeDeliveryText: dataContent.CartCongratsForFreeDeliveryText,
            RegisterBtnTxt: dataContent.RegisterBtnTxt,
            LoginBtnText: dataContent.LoginBtnText,
            LogoutBtnText: dataContent.LogoutBtnText,
            ConfirmLogoutText: dataContent.ConfirmLogoutText,
            ConfirmLogoutYesText: dataContent.ConfirmLogoutYesText,
            ConfirmLogoutNoText: dataContent.ConfirmLogoutNoText,
            MenuChangeStoreText: dataContent.MenuChangeStoreText,
            MenuWelcomeMessage: dataContent.MenuWelcomeMessage,
            MenuAccountText: dataContent.MenuAccountText,
            MenuOrdersListText: dataContent.MenuOrdersListText,
            MenuWalletText: dataContent.MenuWalletText,
            MenuLoyaltyText: dataContent.MenuLoyaltyText,
            MenuIPhoneText: dataContent.MenuIPhoneText,
            MenuAndroidText: dataContent.MenuAndroidText,
            MenuMoreInAppText: dataContent.MenuMoreInAppText,
            AboutUsText: dataContent.AboutUsText,
            TermsAndConditionsText: dataContent.TermsAndConditionsText,
            PrivacyPolicyText: dataContent.PrivacyPolicyText,
            LoyaltyProgramTitle: dataContent.LoyaltyProgramTitle,
            RemoveLoyaltyBtnText: dataContent.RemoveLoyaltyBtnText,
            LoyaltyPointsText: dataContent.LoyaltyPointsText,
            LoyaltyLastUpdatedOnText: dataContent.LoyaltyLastUpdatedOnText,
            LoyaltyInvalidText: dataContent.LoyaltyInvalidText,
            LoyaltyInvalidDescriptionText: dataContent.LoyaltyInvalidDescriptionText,
            LoyaltyAddBtnText: dataContent.LoyaltyAddBtnText,
            LoyaltyAddDialogTitle: dataContent.LoyaltyAddDialogTitle,
            LoyaltyCardNumberText: dataContent.LoyaltyCardNumberText,
            LoyaltyCardNumberHintText: dataContent.LoyaltyCardNumberHintText,
            LoyaltyPhoneNumberText: dataContent.LoyaltyPhoneNumberText,
            LoyaltyPhoneNumberHintText: dataContent.LoyaltyPhoneNumberHintText,
            LoyaltyRequestCardBtnText: dataContent.LoyaltyRequestCardBtnText,
            LoyaltyRequestCardText: dataContent.LoyaltyRequestCardText,
            LoyaltyRequestCardSubmitBtnText: dataContent.LoyaltyRequestCardSubmitBtnText,
            LoyaltySubscribeText: dataContent.LoyaltySubscribeText,
            LoyaltySubscribeDescriptionText: dataContent.LoyaltySubscribeDescriptionText,
            CheckoutDeliveryAddressSectionTitleText: dataContent.CheckoutDeliveryAddressSectionTitleText,
            CheckoutCompleteAddressHintText: dataContent.CheckoutCompleteAddressHintText,
            CheckoutETAText: dataContent.CheckoutETAText,
            CheckoutOrderPaidByEWalletText: dataContent.CheckoutOrderPaidByEWalletText,
            CheckoutOrderPaidByLoyaltyText: dataContent.CheckoutOrderPaidByLoyaltyText,
            CheckoutRemainingToBePaidText: dataContent.CheckoutRemainingToBePaidText,
            CheckoutSlotScheduleBetweenText: dataContent.CheckoutSlotScheduleBetweenText,
            CheckoutScheduleDialogTitle: dataContent.CheckoutScheduleDialogTitle,
            CheckoutScheduleDialogTimeSlotsText: dataContent.CheckoutScheduleDialogTimeSlotsText,
            CheckoutPromocodeDialogTitle: dataContent.CheckoutPromocodeDialogTitle,
            CheckoutPromocodeDialogHintText: dataContent.CheckoutPromocodeDialogHintText,
            CheckoutPromocodeDialogSubmitBtnText: dataContent.CheckoutPromocodeDialogSubmitBtnText,
            CheckoutPaymentMethodDialogTitle: dataContent.CheckoutPaymentMethodDialogTitle,
            CheckoutPaymentMethodDialogOnlinePaymentAddCardText: dataContent.CheckoutPaymentMethodDialogOnlinePaymentAddCardText,
            CheckoutSpecialRequestDialogTitle: dataContent.CheckoutSpecialRequestDialogTitle,
            CheckoutSpecialRequestDialogHintText: dataContent.CheckoutSpecialRequestDialogHintText,
            CheckoutSpecialRequestDialogSubmitBtnText: dataContent.CheckoutSpecialRequestDialogSubmitBtnText,
            CheckoutCompleteAddressRequiredErrorText: dataContent.CheckoutCompleteAddressRequiredErrorText,
            CheckoutSubmitBtnText: dataContent.CheckoutSubmitBtnText,
            CheckoutPaymentDetailsSectionTitle: dataContent.CheckoutPaymentDetailsSectionTitle,
            CheckoutOtherPaymentMethodSectionTitle: dataContent.CheckoutOtherPaymentMethodSectionTitle,
            CheckoutEWalletBalanceTitle: dataContent.CheckoutEWalletBalanceTitle,
            CheckoutLoyaltyBalanceTitle: dataContent.CheckoutLoyaltyBalanceTitle,
            CheckoutEWalletBalanceText: dataContent.CheckoutEWalletBalanceText,
            CheckoutPaymentCurrencySectionTitle: dataContent.CheckoutPaymentCurrencySectionTitle,
            CheckoutPaymentCurrencyTerms: dataContent.CheckoutPaymentCurrencyTerms,
            CheckoutChangeBtnText: dataContent.CheckoutChangeBtnText,
            CheckoutOrderSummarySectionTitle: dataContent.CheckoutOrderSummarySectionTitle,
            EWalletBalanceText: dataContent.EWalletBalanceText,
            EWalletBalanceInText: dataContent.EWalletBalanceInText,
            EWalletNoTransactions: dataContent.EWalletNoTransactions,
            AuthenticationPhoneNumberText: dataContent.AuthenticationPhoneNumberText,
            AuthenticationPhoneNumberHintText: dataContent.AuthenticationPhoneNumberHintText,
            AuthenticationPasswordText: dataContent.AuthenticationPasswordText,
            AuthenticationPasswordHintText: dataContent.AuthenticationPasswordHintText,
            ForgotPasswordPhoneNumberTitle: dataContent.ForgotPasswordPhoneNumberTitle,
            ForgotPasswordPhoneNumberText: dataContent.ForgotPasswordPhoneNumberText,
            ForgotPasswordPhoneNumberHintText: dataContent.ForgotPasswordPhoneNumberHintText,
            ForgotPasswordPasswordText: dataContent.ForgotPasswordPasswordText,
            ForgotPasswordPasswordHintText: dataContent.ForgotPasswordPasswordHintText,
            ForgotPasswordValidationTitle: dataContent.ForgotPasswordValidationTitle,
            ForgotPasswordSubmitBtnText: dataContent.ForgotPasswordSubmitBtnText,
            ForgotPasswordOTPContentText: dataContent.ForgotPasswordOTPContentText,
            ForgotPasswordOTPResendInSMSText: dataContent.ForgotPasswordOTPResendInSMSText,
            ForgotPasswordOTPResendSMSText: dataContent.ForgotPasswordOTPResendSMSText,
            AuthenticationMobileRequiredErrorText: dataContent.AuthenticationMobileRequiredErrorText,
            AuthenticationPasswordRequiredErrorText: dataContent.AuthenticationPasswordRequiredErrorText,
            AuthenticationPasswordLengthErrorText: dataContent.AuthenticationPasswordLengthErrorText,
            ReceiptSubtotalText: dataContent.ReceiptSubtotalText,
            ServiceFeesText: dataContent.ServiceFeesText,
            ServiceFeesIncludingDeliveryFeesText: dataContent.ServiceFeesIncludingDeliveryFeesText,
            DiscountFeesText: dataContent.DiscountFeesText,
            DRSFees: dataContent.DRSFees,
            TotalFeesText: dataContent.TotalFeesText,
            ReceiptChangeDueToWeightAccuracyReceiptNotFinalText: dataContent.ReceiptChangeDueToWeightAccuracyReceiptNotFinalText,
            ReceiptItemReplacedwithText: dataContent.ReceiptItemReplacedwithText,
            ReceiptItemRemovedFromOrderText: dataContent.ReceiptItemRemovedFromOrderText,
            ReceiptItemRemovedFromOrderByUserText: dataContent.ReceiptItemRemovedFromOrderByUserText,
            AccountDetailsSectionTitleText: dataContent.AccountDetailsSectionTitleText,
            AccountDetailsFirstNameText: dataContent.AccountDetailsFirstNameText,
            AccountDetailsFirstNameHintText: dataContent.AccountDetailsFirstNameHintText,
            AccountDetailsLastNameText: dataContent.AccountDetailsLastNameText,
            AccountDetailsLastNameHintText: dataContent.AccountDetailsLastNameHintText,
            AccountDetailsEmailText: dataContent.AccountDetailsEmailText,
            AccountDetailsEmailHintText: dataContent.AccountDetailsEmailHintText,
            AccountDetailsPhoneNumberText: dataContent.AccountDetailsPhoneNumberText,
            AccountDetailsPhoneNumberHintText: dataContent.AccountDetailsPhoneNumberHintText,
            AccountDetailsDateOfBithText: dataContent.AccountDetailsDateOfBithText,
            AccountDetailsDateOfBithHintText: dataContent.AccountDetailsDateOfBithHintText,
            AccountDetailsGenderText: dataContent.AccountDetailsGenderText,
            AccountDetailsGenderHintText: dataContent.AccountDetailsGenderHintText,
            AccountDetailsSaveBtnText: dataContent.AccountDetailsSaveBtnText,
            AccountDetailsEmptyFristOrLastNameErrorText: dataContent.AccountDetailsEmptyFristOrLastNameErrorText,
            CheckoutPageTitleText: dataContent.CheckoutPageTitleText,
            OrdersPageTitle: dataContent.OrdersPageTitle,
            OrderSummaryPageTitle: dataContent.OrderSummaryPageTitle,
            AccountDetailsPageTitle: dataContent.AccountDetailsPageTitle,
            WalletPageTitle: dataContent.WalletPageTitle,
            LoyaltyPageTitle: dataContent.LoyaltyPageTitle,
            UnknownGenderText: dataContent.UnknownGenderText,
            MaleGenderText: dataContent.MaleGenderText,
            FemaleGenderText: dataContent.FemaleGenderText,
            OrderCancelledStatusText: dataContent.OrderCancelledStatusText,
            OrderScheduledStatusText: dataContent.OrderScheduledStatusText,
            OrderNewStatusText: dataContent.OrderNewStatusText,
            OrderOngoingStatusText: dataContent.OrderOngoingStatusText,
            OrderAwaitingReplacementsStatusText: dataContent.OrderAwaitingReplacementsStatusText,
            OrderReplacementsSelectedStatusText: dataContent.OrderReplacementsSelectedStatusText,
            OrderAlternativesTimeoutStatusText: dataContent.OrderAlternativesTimeoutStatusText,
            OrderCollectionDoneStatusText: dataContent.OrderCollectionDoneStatusText,
            OrderReadyForDeliveryStatusText: dataContent.OrderReadyForDeliveryStatusText,
            OrderReadyForPickuStatusText: dataContent.OrderReadyForPickuStatusText,
            OrderInDeliveryStatusText: dataContent.OrderInDeliveryStatusText,
            OrderDeliveredStatusText: dataContent.OrderDeliveredStatusText,
            OrderPickupDoneStatusText: dataContent.OrderPickupDoneStatusText,
            OrderCompletedStatusText: dataContent.OrderCompletedStatusText,
            OrderCancelledStatusDescription: dataContent.OrderCancelledStatusDescription,
            OrderNewStatusDescription: dataContent.OrderNewStatusDescription,
            OrderOngoingStatusDescription: dataContent.OrderOngoingStatusDescription,
            OrderAwaitingReplacementStatusDescription: dataContent.OrderAwaitingReplacementStatusDescription,
            OrderReplacementsSelectedStatusDescription: dataContent.OrderReplacementsSelectedStatusDescription,
            OrderAlternativesTimeoutStatusDescription: dataContent.OrderAlternativesTimeoutStatusDescription,
            OrderCollectionCompletedStatusDescription: dataContent.OrderCollectionCompletedStatusDescription,
            OrderReadyForDeliveryStatusDescription: dataContent.OrderReadyForDeliveryStatusDescription,
            OrderReadyForPickupStatusDescription: dataContent.OrderReadyForPickupStatusDescription,
            OrderInDeliveryStatusDescription: dataContent.OrderInDeliveryStatusDescription,
            OrderDeliveredStatusDescription: dataContent.OrderDeliveredStatusDescription,
            OrderPickupDoneStatusDescription: dataContent.OrderPickupDoneStatusDescription,
            OrderCompletedStatusDescription: dataContent.OrderCompletedStatusDescription,
            ongoingGrpStatus: dataContent.ongoingGrpStatus,
            completedGrpStatus: dataContent.completedGrpStatus,
            cancelledGrpStatus: dataContent.cancelledGrpStatus,
            OrdersPageEmptyViewText: dataContent.OrdersPageEmptyViewText,
            OrdersETAText: dataContent.OrdersETAText,
            OrdersCompletedDateTimeText: dataContent.OrdersCompletedDateTimeText,
            OrdersCancelledDateTimeText: dataContent.OrdersCancelledDateTimeText,
            OrderIdAndTypeInfo: dataContent.OrderIdAndTypeInfo,
            OrderIdAndTypeInfoAgnosticStore: dataContent.OrderIdAndTypeInfoAgnosticStore,
            OrderSummaryCompletedOnDateTimeText: dataContent.OrderSummaryCompletedOnDateTimeText,
            OrderSummaryCancelledOnDateTimeText: dataContent.OrderSummaryCancelledOnDateTimeText,
            OrderSummaryETAOnDateTimeText: dataContent.OrderSummaryETAOnDateTimeText,
            OrderSummaryOrderTypeFromBranchText: dataContent.OrderSummaryOrderTypeFromBranchText,
            OrderSummaryOrderTypeFromAgnosticBranchText: dataContent.OrderSummaryOrderTypeFromAgnosticBranchText,
            OrderSummaryOrderDetailsSectionTitle: dataContent.OrderSummaryOrderDetailsSectionTitle,
            OrderSummaryUserPersonalInfoText: dataContent.OrderSummaryUserPersonalInfoText,
            OrderSummaryUserAddressText: dataContent.OrderSummaryUserAddressText,
            OrderSummarySpecialRequestText: dataContent.OrderSummarySpecialRequestText,
            OrderSummaryDeliveryTokenText: dataContent.OrderSummaryDeliveryTokenText,
            OrderSummaryPickupTokenText: dataContent.OrderSummaryPickupTokenText,
            OrderSummaryViewReceiptBtnText: dataContent.OrderSummaryViewReceiptBtnText,
            OrderSummaryTotalAmountText: dataContent.OrderSummaryTotalAmountText,
            OrderSummaryMoreItemsText: dataContent.OrderSummaryMoreItemsText,
            OrderSummaryReceiptDialogTitle: dataContent.OrderSummaryReceiptDialogTitle,
            PaymentMethodUnknownText: dataContent.PaymentMethodUnknownText,
            PaymentMethodCashText: dataContent.PaymentMethodCashText,
            PaymentMethodCashOnDeliveryDescriptionText: dataContent.PaymentMethodCashOnDeliveryDescriptionText,
            PaymentMethodCashOnPickupDescriptionText: dataContent.PaymentMethodCashOnPickupDescriptionText,
            PaymentMethodCardText: dataContent.PaymentMethodCardText,
            PaymentMethodCardDescriptionOnDeliveryText: dataContent.PaymentMethodCardDescriptionOnDeliveryText,
            PaymentMethodCardDescriptionOnPickupText: dataContent.PaymentMethodCardDescriptionOnPickupText,
            PaymentMethodOnlinePaymentText: dataContent.PaymentMethodOnlinePaymentText,
            PaymentMethodOnlinePaymentDescriptionText: dataContent.PaymentMethodOnlinePaymentDescriptionText,
            OrderTypeUnknownTitle: dataContent.OrderTypeUnknownTitle,
            OrderTypeDeliveryTitle: dataContent.OrderTypeDeliveryTitle,
            OrderTypePickupTitle: dataContent.OrderTypePickupTitle,
            OrderTypeInStoreTitle: dataContent.OrderTypeInStoreTitle,
            OrderTypeOtherTitle: dataContent.OrderTypeOtherTitle,
            AppStoreText: dataContent.AppStoreText,
            GooglePlayText: dataContent.GooglePlayText,
            isAlcoholDialogTitle: dataContent.isAlcoholDialogTitle,
            isAlcoholDialogContent: dataContent.isAlcoholDialogContent,
            isTobaccoDialogTitle: dataContent.isTobaccoDialogTitle,
            isTobaccoDialogContent: dataContent.isTobaccoDialogContent,
            DialogCancelBtnText: dataContent.DialogCancelBtnText,
            DialogSubmitBtnText: dataContent.DialogSubmitBtnText,
            EmptyProductsPage: dataContent.EmptyProductsPage,
            SearchProductsResultTitle: dataContent.SearchProductsResultTitle
        } as AppDataContentV2;
    }
    

    
    static replacePlaceholder(template: string, ...values: string[]): string {
        return template.replace(/{(\d+)}/g, (match, index) => {
            return typeof values[index] !== 'undefined' ? values[index] : match;
        });
    }
}