
import PaymentMethod from '@/models/PaymentMethod'
import UITitle from "@/components/UI/Text/UITitle.vue";

import Vue from 'vue'
import { mapState } from 'vuex'
import MainDialogCard from '../MainDialogComponents/MainDialogCard.vue';
import CloseIcon from '../UI/Icons/CloseIcon.vue';
import StoreSettings from '@/models/StoreSettings';
import _requestHelper from '@/apiManager/_requestHelper';
import OnlinePaymentMethod from '@/models/OnlinePaymentMethod';
export default Vue.extend({
    components: {
        "ui-title": UITitle,
        MainDialogCard,
        "close-icon": CloseIcon
    },

    props: ["selectedPaymentMethod", "orderType", "dialog", "paymentMethodToCompare"],

    data: () => ({
        availableListOfPaymentMethods: [] as PaymentMethod[],
        listOfOnlinePaymentMethods: [] as OnlinePaymentMethod[],
        selectedOnlinePaymentMethodToPayWith: { id: 0 } as OnlinePaymentMethod,
        step: 1 as number,
        initiateCheckoutFormLoading: false as boolean,
        resetSelectedPaymentMethod: false as boolean,
        onlinePaymentWindow: {} as Window | null,
        loading: false as boolean,
    }),

    computed: {
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("partner", ["appDataContentV2"]),
        ...mapState("firebase", ["firebaseDb"]),

        chosenPaymentMethod: {
            get(): PaymentMethod {
                return this.selectedPaymentMethod;
            },

            set(value: PaymentMethod) {
                this.$emit("changed", value);
            }
        }
    },

    model: {
        event: "changed",
        prop: "selectedPaymentMethod"
    },


    methods: {
        getAvailablePaymentMethodsByOrderType() {
           this.availableListOfPaymentMethods = PaymentMethod.getListOfAvailablePaymentMethodsByOrderType(this.orderType);        
        },

        setSelectedPaymentType(paymentMethod: PaymentMethod, closeDialog: boolean = true) {            
            this.chosenPaymentMethod = paymentMethod;

            // if online payment is set, go to step 2 to choose a card
            if(paymentMethod.pMethodInt == PaymentMethod.OnlieCardPayment)
                this.step = 2;

            // if not, close dialog
            if(paymentMethod.pMethodInt != PaymentMethod.OnlieCardPayment && closeDialog == true)
                this.$emit("closeDialog");
        },

        setOnlinePaymentMethod(onlinePaymentMethod: OnlinePaymentMethod) {
            this.selectedOnlinePaymentMethodToPayWith = onlinePaymentMethod;
            this.$emit("selectedOnlinePaymentMethod", this.selectedOnlinePaymentMethodToPayWith);
            
            this.$emit("closeDialog");
            this.resetSelectedPaymentMethod = false;
            this.step = 1;
        },

        backFromOnlinePaymentMethodsList() {
            this.step = 1;

            if(this.availableListOfPaymentMethods.length > 1) {
                this.setSelectedPaymentType(this.availableListOfPaymentMethods[0], false);
            }
            this.selectedOnlinePaymentMethodToPayWith = {id: 0} as OnlinePaymentMethod;
        },

        //#region Online Paymnet
        initiateCheckoutForm() {
            this.initiateCheckoutFormLoading = true;

            _requestHelper({
                url: "payment/form",
                method: "POST",
                data: {
                    currencyCode: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode,
                    branchId: ((this as any).selectedStoreDetails as StoreSettings).id,
                },
                loaderKey: "",
                successCallback: async (result: any) => {

                    // Open a new window
                    this.onlinePaymentWindow = window.open(result.settings.url, "NewWindow", "width=600, height=400");
                    this.initiateCheckoutFormLoading = false
                }
            })
            .finally(() => this.initiateCheckoutFormLoading = false)
        },


        getListOfOnlinePaymentMethods(shouldUpdateFromOrigin: boolean = false) {
            this.loading = true;

            return _requestHelper({
                loaderKey: "loading",
                method: "POST",
                url:"payment/list",
                data: { 
                    currencyCode: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode, 
                    shouldUpdateFromOrigin: shouldUpdateFromOrigin,
                    branchId: this.selectedStoreDetails.id
                },
                successCallback: (listOfOnlinePaymentMethods: any) => {
                this.listOfOnlinePaymentMethods = listOfOnlinePaymentMethods;
                this.selectedOnlinePaymentMethodToPayWith = this.listOfOnlinePaymentMethods.find(x => x.isDefault) as OnlinePaymentMethod;

                }
            })
            .catch(() => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        handleMessageEvent(event : any) {
            let data = event.data;
            if(data.type === "payment_settings_changed"){
                let settings = data.settings;
                if(settings.closeView == true){
                    this.getListOfOnlinePaymentMethods(true); 
                }
            }
        }

    //#endregion
 

    },


    watch: {
        dialog(newVal: boolean) {
            if(newVal == true)
                this.getAvailablePaymentMethodsByOrderType();
        },

        selectedPaymentMethod(value: PaymentMethod) {
            if(value.pMethodInt == PaymentMethod.OnlieCardPayment) {
                this.step == 2;
                this.getListOfOnlinePaymentMethods(false);
            }

            else {
                this.selectedOnlinePaymentMethodToPayWith = {
                    id: 0
                } as OnlinePaymentMethod; 
            }
        },

        step(val: number, oldVal) {
            if(val == 2) {
                this.getAvailablePaymentMethodsByOrderType();
            }
        }
    },

    mounted() { 
        this.getAvailablePaymentMethodsByOrderType();

        // listen for message
        window.addEventListener("message", this.handleMessageEvent); 
    }

})
