
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import WsaTextField from "@/components/UI/TextFields/WsaTextField.vue";
import WsaButton from "@/components/UI/Button/wsa-button.vue";
import Cart from '@/models/Cart';
import User from '@/models/User';
import StoreSettings from '@/models/StoreSettings';
import UITitle from '../UI/Text/UITitle.vue';
import CloseIcon from '../UI/Icons/CloseIcon.vue';

export default Vue.extend({
    props: ["receiptGenerator", "promocode", "isOpen"],

  components: { 
    WsaTextField,
    WsaButton,    
    "ui-title": UITitle,
    "close-icon": CloseIcon
   },

   data: () => ({
        promoCodeValue: "" as string, 
        loading: false as boolean
   }),

   computed: {
        ...mapState("partner", ["appDataContentV2"]),
        ...mapState("store", ["selectedStoreDetails"]),
        ...mapState("auth", ["preferredOrderType"]),

        ...mapGetters("auth", ["currentSelectedUser"]),
        ...mapGetters("cart", ["currentSelectedCart"])
   },

   methods: {
    ...mapActions("order", ["validatePromoCode"]),
    ...mapMutations("order", ["CLEAR_PROMOTION"]),

        // validate promocode
    async validatePromotion() {
      
      if (this.promoCodeValue.trim().length > 0) {
        this.loading = true;

        await this.validatePromoCode({
          promoCodeValue: (this as any).promoCodeValue,
          receipt: (this as any).receiptGenerator(),
          branchId: (this as any).selectedStoreDetails.id,
          orderId: null,
          currencyCode: ((this as any).selectedStoreDetails as StoreSettings).defaultCurrency.currencyCode,
          shopperCoordinates: {
            latitude: ((this as any).currentSelectedUser as User).usedAddress?.position.latitude,
            longitude: ((this as any).currentSelectedUser as User).usedAddress?.position.longitude
          },
          orderType: this.preferredOrderType.typeInt,
          itemsInPC: ((this as any).currentSelectedCart as Cart).items.map( item => {
            return {
              quantity: item.quantity,
              itemId: item.itemId,
              price: item.price,
              adjPriceValue: item.adjPrice
            }
          }),
        }).then(() => {
            this.$emit("onPromoValidation", this.promoCodeValue);
            this.loading = false;
        })
        .catch(() => {
          this.CLEAR_PROMOTION();
          this.$emit("onPromoValidation", this.promoCodeValue);
          this.loading = false;
        });
      }
    },
   },

   watch: {
    isOpen() {
        this.promoCodeValue = this.promocode;
    }
   }
    
})
