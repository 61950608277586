<template>
<div tile  v-click-outside="emptySearch" class="autocomplete-container" >
    <v-text-field
        height="52"
        full-width
        flat        
        hide-details 
        solo 
        persistent-placeholder 
        background-color="#fff" 
        style="border-radius: 18px"       
        class="ms-auto"
        :class="listOfPredictions.length > 0 ?'v-input--is-focused' : null"
        v-model="searchPattern"
        :placeholder="appDataContentV2.searchAddressHint"        
        append-icon="mdi-magnify"                   
        light
      >
        <template v-slot:append>
          <v-icon @click="emptySearch" v-if="listOfPredictions.length > 0 || searchPattern.trim().length > 0">mdi-close-circle</v-icon>
        </template>

        <template v-slot:prepend-inner>
          <v-icon color="primary">mdi-map-marker</v-icon>
        </template>
    </v-text-field>

    <v-list class="py-0 autocomplete-dropdown " style=" " v-if="listOfPredictions.length > 0" light>
        <span v-for="(place, i) in listOfPredictions" :key="i">
            <v-list-item  class="cursor-pointer" @click="selectPlace(place)">
                <v-list-item-icon>
                    <v-icon>mdi-map-marker-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content class="px-0">
                    <v-list-item-title style="font-size: 15px">
                    {{ place.description }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="i != listOfPredictions.length - 1"/>
        </span>
    </v-list>

</div>
</template>

<script lang="js">
import Vue from 'vue'
import {mapState} from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue';
import _requestHelper from '@/apiManager/_requestHelper';
import { Apis } from '@/apiManager/Apis';

export default Vue.extend({
    data: () => ({
        searchPattern: "",
        listOfPredictions: [],
        isListShown: true,
        isCloseButtonShown: true,
    }),

    props: ['mapObject'],

    computed: {
        ...mapState("partner", ["appDataContentV2", "partnerSettings"]),

        google: getGoogleMapsAPI, 
    },

    methods: {
        emptySearch() {
            this.$emit("disabledAutocompleteSearch");
            this.listOfPredictions = [];
            this.searchPattern = "";
        },

        //Get place predictions
        async getPlacePredictions() {
            clearTimeout(this.debounce);

            this.debounce = setTimeout(async () => {
                await _requestHelper({
                    method: "POST",
                    url: Apis.mapAutocompletePlacesSubUrl,
                    data: {
                        searchLocation: this.searchPattern,
                        requestedFrom: 5
                    },
                    successCallback: (result) => {
                        this.listOfPredictions = result;
                    }

                })
            }, 500)
        },

        async selectPlace(place) {

            await _requestHelper({
                method: "POST",
                url: Apis.mapPlaceDetailsUrl,
                data: {
                    placeId: place.place_id
                },
                successCallback: (result) => {
                    this.$emit('place_changed', result);

                    this.listOfPredictions = [];
                    this.searchPattern = "";
                }

            })
        }
    },

    watch: {
        searchPattern(value) {
            if(value.length > 0) {
                this.getPlacePredictions(); 
            }

            else {
                this.listOfPredictions = [];
            }

        }
    }
})
</script>


<style lang="scss" scoped>


.autocomplete-container {
    width: 100%;
    position: relative;
    border-radius: 10px!important; 

    .autocomplete-dropdown {
        left:0; 
        right:0;
        padding: 0;
    }
}

</style>