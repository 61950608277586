
import Vue from "vue";
import { mapState } from "vuex";
import routerManager from "@/routerManager";

export default Vue.extend({

  props: ["pageTitle"],

  computed: {
    ...mapState("store", ["selectedStoreDetails"]),
    ...mapState("ui", ["isMobileViewDisplayed"]),

    getGoBackRoute(): any {
      if(this.$route.name === "OrderSummaryPage") {
        return  {name: 'OrdersPage'};
      }

      else if(typeof (this as any).selectedStoreDetails.id == "undefined") {
        return  {name: 'storesPage'};
      }
      else {
        return {
          name: 'storeHomePage', 
          params: {
            storeId: (this as any).selectedStoreDetails.id,
            storeDisplayName: (this as any).selectedStoreDetails.displayName.replaceAll(' ', '-').toLocaleLowerCase()
          }
        }
      }
    }
  },

  methods: {
    goBack() {
      routerManager.goToStoreHomePage()
    }
  },
});
