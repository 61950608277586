import StoreService from "@/services/storeService";
import AddressCoordinates from "./AddressCoordinates";
import AddressPosition from "./AddressPosition";
import AddressTag from "./AddressTag";
import Cart from "./Cart";
import Currency from "./Currency";
import {OrderStatus} from "./OrderStatus";
import OrderType from "./OrderType";
import PartnerSettings from "./PartnerSettings";
import PaymentMethod from "./PaymentMethod";
import Product from "./Product";
import Receipt from "./Receipt";
import ScheduleSlot from "./ScheduleSlot";
import Store from "./Store";

export default class Order {
    id: number = 0;                                               // Order system ID
    tempId: string = "";                                          // Temporary Id created on client side
    displayId: string = "";                                   // Order display ID
    type: OrderType = {} as OrderType;                        // checks if order is a pickup or delivery order
    cartOrdered: Cart = {} as Cart;                           // Shopping Cart of Order
    shopper = {};                                           // The shopper
    deliveryAdr: AddressPosition = {} as AddressPosition;    // Delivery Address
    specialRequest: string = "";          // Delivery special request   
    store: Store = {} as Store;                    // Store/Branch on which order was done 
    status: OrderStatus = {} as OrderStatus;             // Order status
    receipt: Receipt = {} as Receipt;                // the Order receipt
    // PromoCode promoCode;             // promo code
    eTA: string = "";                   // prefered order time ==> set to Now() by default
    doneTime: string = "";              // delivered or pick up time
    completedTime: string = "";         // completed time (after rating or not)c
    paymentMethod: PaymentMethod = {} as PaymentMethod;    // provides the payment method
    maxDateToProvideAlt: string = "";   // Max waiting time for alternatives
    cancelledOrderDate: string = "";    // If Order is cancelled, log the cancellation date here
    wasInAltFlow: boolean = false;      // Was order in alternative flow
    orderStats = {};      // Statistics about the progress in the collection
    progress: number = 0;
    customerRating: number = 0;          // customer rating
    customerComment: string = "";         // customer comment
    selectedSlot: ScheduleSlot = {} as ScheduleSlot;      // selected slot
    selectedSlotId: number = 0;             // selected slot id
    scheduledDateStart: string = "";    // scheduled date start
    scheduledDateEnd: string = "";      // scheduled date start
    // Boomrang boomrang;              // boomrang merchants
    editOrderTimeOut: string = "";      // edit order timeout
    currency: Currency = {} as Currency;              // order currency
    customerReceptionToken: string = "";  // the token that confirms that customer has received his order
    partnerSettings: PartnerSettings = {} as PartnerSettings; // order partner settings -> Mainly used for marketplace to get the partner settings of the specific store from which the order was made to be used in order summary and edit order flow 
    deliveryDriver = {}; // the delivery driver info
    deliveryRoute: string = "";     // the driving route between the user and store
    deliveryZoneId: number = 0;
    pickupZoneId: number = 0;
    isUsingReusableBags: boolean = false;       // On checkout user can select the option to get his order in reusable bags that the driver will take them back with him
    items: Product = {} as Product;
    statusDisplaySentence: string = "";
    payUsingEWallet: boolean = false;
    payUsingPartnerLoyalty: boolean = false;
    driverTrackingId: string | null = null;

    static fetchOrderObjectFromJson(order: any): Order {
        var singleOrder = {} as Order;
        var store = new Store();

        singleOrder.id = order.id;
        singleOrder.tempId = order.tempId;
        singleOrder.displayId = order.displayId;
        singleOrder.type = OrderType.getOrderTypeFromInt(order.orderType);
        
        // TODO: add cart
        singleOrder.shopper = order.user;
        singleOrder.specialRequest = order.specialRequest;
        singleOrder.store = store.fetchStoreObjectFromJson(order.branch);
        singleOrder.status = OrderStatus.getStatusFromStatusInt(order.status);
        singleOrder.eTA = order.estimatedDateOfArrival;
        singleOrder.doneTime = order.doneDateTime
        singleOrder.completedTime = order.completedDateTime;
        singleOrder.paymentMethod = PaymentMethod.getPaymentMethodsFromInt(order.paymentMethod);
        singleOrder.cancelledOrderDate = order.cancelledDateTime;
        singleOrder.wasInAltFlow = order.wasInAlternativeState;
        singleOrder.orderStats = order.orderItemsStats;
        singleOrder.customerRating = order.ratingScore;
        singleOrder.customerComment = "";
        singleOrder.items = order.items;
        singleOrder.statusDisplaySentence = order.statusDisplaySentence;

        singleOrder.receipt = order.receipt;

        singleOrder.customerReceptionToken = order.customerReceptionToken;
        singleOrder.deliveryDriver = order.driver;
        singleOrder.deliveryRoute = typeof order.routePolyline !='undefined' ? order.routePolyline : null;
        singleOrder.partnerSettings = PartnerSettings.fetchPartnerSettingsObjectFromJson(order.partnerSettings);
        singleOrder.isUsingReusableBags = order.isUsingReusableBags;
        singleOrder.deliveryZoneId = order.deliveryZoneId;
        singleOrder.pickupZoneId = order.pickupZoneId;
        singleOrder.progress = order.progress;

        singleOrder.currency = {
            id: order.currency.id,
            currencyCode: order.currency.currencyCode,
            currencyDescription: order.currency.currencyDescription,
            isDefault: false,
            roundingType: order.currency.roundingType,
        }
        
        singleOrder.selectedSlot = {
            day: order.slot.day,
            id: order.slot.id,
            endTime: order.slot.end,
            startTime: order.slot.start,
            isInstant: order.slot.isInstant,
            remainingCapacity: order.slot.remainingCapacity,
            isAvailable: order.slot.isAvailable,
            isFistAvailableSlot: false,
            instantThreshold: order.slot.instantThreshold,
            e2EProcessingTimeInMin: order.slot.e2EProcessingTimeInMin,
            e2EProcessingTimeInMinHigh: order.slot.e2EProcessingTimeInMinHigh,
            e2EProcessingTimeInMinLow: order.slot.e2EProcessingTimeInMinLow,
            date: order.slot.date,
            eta: order.slot.eta
        };

        singleOrder.selectedSlotId = singleOrder.selectedSlot.id;
        
        singleOrder.deliveryAdr = {
            additionalAddressInfo: order.address.additionalAddressInformation,
            id: order.address.id,
            area: order.address.area,
            city: order.address.city,
            county: order.address.area,
            label: order.address.label,
            isDefault: false,
            position: {
                latitude: order.address.coordinatesLatitude,
                longitude: order.address.coordinatesLongitude,
            } as AddressCoordinates,
            country: order.address.country,
            countryCode: order.address.countryCode,
            postalCode: order.address.postalCode,
            state: order.address.state,
            streetName: order.address.streetName,
            streetNbre: order.address.streetNumber,
            tag: AddressTag.getTagFromAddressTagId(order.address.tag),
            tagDescription: order.address.tagDescription,
            isPrimary: false,
            district: "",
            houseNumber: "",
            postalcode: ""
        }

        singleOrder.payUsingEWallet = order.payUsingEWallet;
        singleOrder.payUsingPartnerLoyalty = order.payUsingPartnerLoyalty;
        singleOrder.driverTrackingId = order.driverTrackingId;



        return singleOrder;

        
    }



}