import { EPartnerStoresStructureType } from "@/enums/EPartnerStoresStructureType";
import AddressCoordinates from "./AddressCoordinates";
import StoreSettings from "./StoreSettings";
import Currency from "./Currency";

export default class PartnerSettings{
  // stores
  partnerStoresStructureType: EPartnerStoresStructureType = EPartnerStoresStructureType.SingleStoreModel; // Partner stores type
  isDeliveryFeatureEnabled: boolean = false;    // Delivery orders 
  isPickupFeatureEnabled: boolean = false;  // pickup orders 
  
  // orders & cart
  maxNbreOfItemsInOrder: number = 0;        // maximum number of items per order
  maxOrderTotal: number = 0;          // maximum total amount per order 

  // Cat/Subcat
  isSubCatBgMultiColored: boolean = false;    // Is SubCat Multi Colored
  subCatBgColorList: Array<string> = [];          // SubCat bg multi colors list
  singleSubCatBgColor: string  = "";        // Single SubCat Bg Color
  catSubcatImageSizeComparedToCardSize: number = 0;     // Cat/subcat image size cmpared to card size

  // partner specific
  isLoyaltyProgramEnabled: boolean = false;  // Loyalty program
  partnerId: number = 0;                    // partner id
  partnerName: string  = "";              // Customer Name 
  logoUrl: string  = "";   // Image URL
  urlAbout: string  = "";  // about customer 
  urlTermsAndCond: string  = "";   // url terms & conditions 
  blobUrl: string  = "";   // blobUrl 
  hiResBlobUrl: string  = "";   // HiResBlobUrl 
  partnerLoyaltyProgramUnit: string  = "";          // loyalty program unit   
  urlPrivacyPolicy: string  = "";  // url privacy policy
  mainColor: string  = "";                // Partner Main Color
  secondaryColorUsedForBtnSplash: string  = "";      // Partner secondary Color
  actionButtonColor: string  = "";        // Action Button Color
  cursorAndBtnsColor: string  = "";      // Cursor and btns color
  cursorAndBtnsColorInt: number = 0;        // Cursor and btns color int

  // other settings
  customerServicePhoneNumber: string  = "";            // customer service number in case of account creation issue
  customerServiceWhatsAppNumber: string  = "";      // customer service whatsApp number in case of account creation issue
  maxNbreOfSMSRetry: number = 0;            // can send x times SMS for validation 
  isOnlinePaymentEnabled: boolean = false;    // has online card payment
  smsRetryAwaitingDelayInSeconds: number = 0;
  defaultUserLocationCoordinates: AddressCoordinates = {} as AddressCoordinates; // auto play banners
  welcomePageBackgroundImageUrl: string = "";
  msaGooglePlayUrl: string = "";
  msaAppStoreUrl: string = "";
  locationSearchCountryCodeFilter: Array<string> = [];
  wsaCategoryCardWidthSize: number = 80;
  wsaCategoryCardHeightSize: number = 80;
  isWsaItemCardOutlined: boolean = true;
  isWsaCategoryCardOutlined: boolean = true;
  wsaLandingPageLogoUrl: string = "";
  wsaFooterLogoUrl: string = "";
  appBarLogoUrl: string = "";
  isEWalletFeatureEnabled: boolean = false;
  isEWalletPaymentFeatureEnabled: boolean = false;
  eWalletCardBackgroundColors: string[] = [];
  defaultPartnerLoyaltyCurrency: Currency = {} as Currency;
  isPartnerLoyaltyPaymentFeatureEnabled: boolean = false;
  isDropOffPinEnabled: boolean = false;
  isLightStructure: boolean = false;
  isWsaEnabled: boolean = false;
  contactUsMessageSubjectOptions: string[] = [] as string[];
  appShortLink: string = "" as string;
  isLandingPageReversed: string = "" as string;
  defaultPhoneNumberCode: string = "";
  itemPromotedTagBackgroundColor: string = "";
  itemPromotedTagTextColor: string = "";
  driverTrackingFirestoreCollection: string = "";
  driverMapMarkerURL: string = "";
  facebookUrl: string = "";

  // Social Media
  twitterUrl: string = "";
  instagramUrl: string = "";
  isItemTagOnWSARotated: boolean = false;
  

    // this method is used to fetch json server result into partnerSettings object
  static fetchPartnerSettingsObjectFromJson(partnerSettings: any) : PartnerSettings {
    var settings = {} as PartnerSettings;
    settings.partnerStoresStructureType = PartnerSettings.getEPartnerStoresStructureType(partnerSettings.partnerStoresStructureType);
    settings.isPickupFeatureEnabled = partnerSettings.isPickupFeatureEnabled;
    settings.isDeliveryFeatureEnabled = partnerSettings.isDeliveryFeatureEnabled;

    settings.customerServicePhoneNumber = partnerSettings.customerServiceNumbers;
    settings.customerServiceWhatsAppNumber = partnerSettings.customerServiceWhatsAppNumber;
    settings.maxNbreOfSMSRetry = partnerSettings.maxSmsNbreOfRetries;
    settings.partnerId = partnerSettings.partnerId;
    settings.partnerName = partnerSettings.partnerName;
    settings.urlAbout = partnerSettings.aboutUsUrl;
    settings.urlTermsAndCond = partnerSettings.termsAndConditionsUrl;
    settings.maxNbreOfItemsInOrder = partnerSettings.maxNbreOfItemsInOrder;
    settings.maxOrderTotal = partnerSettings.maxOrderTotal;
    settings.blobUrl = partnerSettings.rlubOlb;
    settings.hiResBlobUrl = partnerSettings.hiResImgBlobUrl;
    settings.partnerLoyaltyProgramUnit = partnerSettings.partnerLoyaltyProgramUnit;
    settings.urlPrivacyPolicy = partnerSettings.privacyPolicy;
    settings.logoUrl = partnerSettings.logoUrl;

    settings.catSubcatImageSizeComparedToCardSize = partnerSettings.catSubcatImageSizeComparedToCardSize;
    settings.isSubCatBgMultiColored = partnerSettings.isSubCatBgMultiColored;
    settings.subCatBgColorList = partnerSettings.subCatBgColorList.map((color: string) => { return color.replace("0xFF", "#"); });
    settings.singleSubCatBgColor = partnerSettings.singleSubCatBgColor == '0x00000000' ? 'transparent' : partnerSettings.singleSubCatBgColor.replace("0xFF", "#");
    settings.mainColor = partnerSettings.mainColor.replace("0xFF", "#");
    settings.actionButtonColor = typeof partnerSettings.actionButtonColor == 'undefined' ? settings.mainColor : partnerSettings.actionButtonColor.replace("0xFF", "#");
    settings.cursorAndBtnsColor = partnerSettings.cursorAndBtnsColor.replace("0xFF", "#");
    settings.isOnlinePaymentEnabled = false; //partnerSettings.isOnlinePaymentEnabled;
    settings.smsRetryAwaitingDelayInSeconds = partnerSettings.smsRetryAwaitingDelayInSeconds;
    settings.welcomePageBackgroundImageUrl = partnerSettings.wsaLandingPageImageUrl;
    settings.msaGooglePlayUrl = partnerSettings.msaGooglePlayUrl;
    settings.msaAppStoreUrl = partnerSettings.msaAppStoreUrl;
    settings.locationSearchCountryCodeFilter = partnerSettings.googlePlacesSearchCountries;
    settings.isItemTagOnWSARotated = partnerSettings.isItemTagOnWSARotated;

    settings.defaultUserLocationCoordinates = {
      latitude: partnerSettings.defaultUserLocationLat, 
      longitude: partnerSettings.defaultUserLocationLong
    };

    settings.wsaCategoryCardWidthSize = partnerSettings.wsaCategoryCardWidthSize || 100; // TODO: to be implemented
    settings.wsaCategoryCardHeightSize = partnerSettings.wsaCategoryCardHeightSize || 100; // TODO: to be implemented
    settings.isWsaItemCardOutlined = true;
    settings.isWsaCategoryCardOutlined =  true;
    settings.wsaLandingPageLogoUrl = partnerSettings.wsaLandingPageLogoUrl;
    settings.wsaFooterLogoUrl = partnerSettings.wsaFooterLogoUrl;
    settings.appBarLogoUrl = partnerSettings.wsaAppBarLogoUrl;
    settings.isEWalletFeatureEnabled = partnerSettings.isEWalletFeatureEnabled;
    settings.isEWalletPaymentFeatureEnabled = partnerSettings.isEWalletPaymentFeatureEnabled;
    settings.eWalletCardBackgroundColors = partnerSettings.wsaWalletCardBackgroundColors != null && typeof partnerSettings.wsaWalletCardBackgroundColors != "undefined"
    ? partnerSettings.wsaWalletCardBackgroundColors.map((color: string) => color.replace("0xFF", "#"))
    : [] as string[];


    // social media
    settings.facebookUrl = partnerSettings.facebookUrl;
    settings.instagramUrl = partnerSettings.instagramUrl;
    settings.twitterUrl = partnerSettings.twitterUrl;

    settings.defaultPartnerLoyaltyCurrency = partnerSettings.defaultPartnerLoyaltyCurrency;
    settings.isLoyaltyProgramEnabled = partnerSettings.isLoyaltyProgramEnabled;
    settings.isPartnerLoyaltyPaymentFeatureEnabled = partnerSettings.isPartnerLoyaltyPaymentFeatureEnabled;

    settings.defaultPhoneNumberCode = partnerSettings.defaultPhoneNumberCode || "+971";
    settings.isDropOffPinEnabled = partnerSettings.isDropOffPinEnabled;
    settings.isLightStructure = partnerSettings.isLightStructure;
    settings.isWsaEnabled = partnerSettings.isWsaEnabled;
    settings.contactUsMessageSubjectOptions = partnerSettings.contactUsMessageSubjectOptions;
    settings.appShortLink = partnerSettings.appShortLink;
    settings.isLandingPageReversed = partnerSettings.isWsaLandingPageReversed;
    settings.itemPromotedTagBackgroundColor = partnerSettings.itemPromotedTagBackgroundColor.replace("0xFF", "#");
    settings.itemPromotedTagTextColor = partnerSettings.itemPromotedTagTextColor.replace("0xFF", "#");
    settings.driverTrackingFirestoreCollection = partnerSettings.driverTrackingFirestoreCollection;
    settings.driverMapMarkerURL = partnerSettings.driverMapMarkerURL;

    return settings;
  }
  
  static getEPartnerStoresStructureType(partnerStoreTypeInt: number): EPartnerStoresStructureType{
    if(partnerStoreTypeInt == 0)
    return EPartnerStoresStructureType.MultiStoreSelectionModel;
    else if(partnerStoreTypeInt == 1)
    return EPartnerStoresStructureType.SingleStoreModel;
    else if(partnerStoreTypeInt == 2)
    return EPartnerStoresStructureType.StoreAgnosticModel;
    return EPartnerStoresStructureType.MultiStoreSelectionModel;
  }

}