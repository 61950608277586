
import { EPartnerStoresStructureType } from '@/enums/EPartnerStoresStructureType';
import OrderType from '@/models/OrderType';
import PartnerSettings from '@/models/PartnerSettings';
import StoreService from '@/services/storeService';
import routerManager from "@/routerManagerNew/routerManager";
import Vue from 'vue'
import { mapState } from 'vuex'
import Store from '@/models/Store';
import moment from "moment";
import Stores from "@/views/Stores/Stores.vue";

export default Vue.extend({

    components: {
        "stores": Stores,
    },
    
    data: () => ({
        deliveryOrderTypeInt: OrderType.deliveryOrderTypeInt,
        pickupOrderTypeInt: OrderType.pickupOrderTypeInt,
        storesDialogState: false as boolean
    }),
    
    computed: {
        ...mapState("auth", ["preferredOrderType"]),
        ...mapState("partner", ["partnerSettings", "appDataContentV2"]),
        ...mapState("schedule", ["storeHomePageFirstAvailableSlots"]),
        ...mapState("store", ["selectedStoreDetails", "agnosticDeliveryStoreId", "listOfDeliveryStores", "listOfPickupStores"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),

        isStoreAgnostic(): boolean {
            return  ((this as any).partnerSettings as PartnerSettings).partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel;
        },


        isCustomerEligibleForDelivery(): boolean {
            var partnerSettings = (this as any).partnerSettings as PartnerSettings;


            // if partner store structure type is agnostic store
            // check if there is any saved agnostic delivery store
            // if available, then delivery order type will be enabled
            if(this.isStoreAgnostic) {
                if( (this as any).listOfDeliveryStores.length > 0 )  {
                    return true;
                }   
                return false;         
            }

            else {
                if((this as any).listOfDeliveryStores.length > 0) {
                    var storeFound: Store =  (this as any).listOfDeliveryStores.find((store: any) => store.id == (this as any).selectedStoreDetails.id) ;
                    return storeFound != null;                    
                }
            }

            return false;
        },

        isCustomerEligibleForPickup(): boolean {
            var storeFound: Store =  (this as any).listOfPickupStores.find((store: any) => store.id == (this as any).selectedStoreDetails.id) ;

            // check if there is any available store in listOfPickupStores
            // if available, then delivery order type will be enabled
            return (this as any).listOfPickupStores.length > 0 && storeFound != null && storeFound.id > 0;                                    
        },

        isDeliveryFirstAvailableSlotDisplayed(): boolean {
            if(this.isStoreAgnostic) {
                if(((this as any).preferredOrderType as OrderType).typeInt == OrderType.deliveryOrderTypeInt) {
                    return true;
                }

                return false;
            }

            return true;
        },

        isPickupFirstAvailableSlotDisplayed(): boolean {
            if(this.isStoreAgnostic) {
                if(((this as any).preferredOrderType as OrderType).typeInt == OrderType.pickupOrderTypeInt) {
                    return true;
                }

                return false;
            }

            return true;
        }
    },


    methods: {
        
        async onOrderTypeClicked(orderType: number) {
            var partnerSettings = (this as any).partnerSettings as PartnerSettings;
            
            // if partner store structure type is single store
            // change the order type  
            if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.SingleStoreModel) {
                this.$store.commit("auth/SET_ORDER_TYPE", orderType);
            }


            // if partner store structure type is multi store
            // change the order type            
            if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.MultiStoreSelectionModel) {
                this.$store.commit("auth/SET_ORDER_TYPE", orderType);
            }


            // if partner store structure type is agnostic store
            // change the order type     
            if(partnerSettings.partnerStoresStructureType == EPartnerStoresStructureType.StoreAgnosticModel) {

                // if delivery order type is selected
                // get the information of the saved agnostic delivery store
                if(orderType == OrderType.deliveryOrderTypeInt) {                    
                    await StoreService.getStoreDetails((this as any).agnosticDeliveryStoreId);

                    // update store home page
                    routerManager.goToStoreHomePage({id: (this as any).selectedStoreDetails.id, displayName: (this as any).selectedStoreDetails.displayName} as Store)
                }

                else if(orderType == OrderType.pickupOrderTypeInt) {
                    this.storesDialogState = true
                }

            }            
        },

        today(): string {
            return moment().format("YYYY-MM-DD");
        },

        moment(day: string): string {
            return moment(day).format("YYYY-MM-DD");
        },

    }
})
